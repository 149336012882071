import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import Inputs from "../components/login/Inputs";
import { Toaster } from "react-hot-toast";
import { TypeAnimation } from "react-type-animation";

const Login = ({ logged }) => {
    const [mode, setMode] = useState("login");

    const variants = {
        active: {
            backgroundColor: "#2d3047",
            color: "#fafafa",
        },
        disabled: {
            backgroundColor: "#fafafa",
            color: "#2d3047",
        },
    };

    return (
        <section className="d-flex align-items-center justify-content-evenly h-100 bg-lightGray">
            <Toaster position="top-left" reverseOrder={false} />
            <div className="d-md-flex d-none flex-fill h-100 text-white p-3 align-items-center justify-content-center bg-login">
                <div style={{ maxWidth: "800px" }}>
                    <h1 className="h1_writer">
                        Il miglior posto dove acquistare{" "}
                        <TypeAnimation
                            sequence={[
                                "centraline",
                                3000,
                                "pompe",
                                3000,
                                "iniettori",
                                3000,
                            ]}
                            wrapper="span"
                            speed={30}
                            repeat={Infinity}
                            className="text-primary"
                        />
                    </h1>
                    <p className="mb-0">Sconti personalizzati per utente</p>
                    <sup>
                        dopo la registrazione contattarci ed aspettare
                        l'attivazione
                    </sup>
                </div>
            </div>
            <div
                className="p-4 m-4 flex-md-grow-0 flex-grow-1"
                style={{ minWidth: "25%", maxWidth: "800px" }}
            >
                <div className="d-flex gap-4 mb-5 justify-content-center">
                    <motion.div
                        //className={`ps-3 pe-3 pt-2 pb-2 cursor-pointer ${mode === "login" ? "text-white bg-primary rounded shadow" : ""}`}
                        className={`ps-3 pe-3 pt-2 pb-2 cursor-pointer`}
                        onClick={() => setMode("login")}
                        whileTap={{ scale: 0.9 }}
                        variants={variants}
                        animate={mode === "login" ? "active" : "disabled"}
                    >
                        <h2>accedi</h2>
                    </motion.div>
                    <motion.div
                        //className={`ps-3 pe-3 pt-2 pb-2 cursor-pointer  ${mode === "registra" ? "text-white bg-primary rounded shadow" : ""}`}
                        className={`ps-3 pe-3 pt-2 pb-2 cursor-pointer`}
                        onClick={() => setMode("registra")}
                        whileTap={{ scale: 0.9 }}
                        variants={variants}
                        animate={mode === "registra" ? "active" : "disabled"}
                    >
                        <h2>registrati</h2>
                    </motion.div>
                </div>

                <Inputs mode={mode} logged={logged} />
            </div>
        </section>
    );
};

export default Login;
