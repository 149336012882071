import React, { useState, useEffect } from "react";
import { Modal, Placeholder } from "react-bootstrap";
import API from "../../API";
import Table from "../Table";
import nf from "../../asset/404.svg";

const DettaglioOrdine = ({ vedi, chiudi, dati }) => {
    //prodotti
    const [loadedProduct, setLoadedProduct] = useState(false);
    const [products, setProducts] = useState([]);
    //indirizzi
    const [loadedAddress, setLoadedAddress] = useState(false);
    const [indirizzi, setIndirizzi] = useState({});

    useEffect(() => {
        if (vedi) {
            console.log(dati);
            getOrdine();
            getIndirizzi();
        } else {
            //vari reset
            setProducts([]);
            setIndirizzi({});
        }
    }, [vedi]);
    
    async function getOrdine() {
        setLoadedProduct(false);
        API.get("prodotti", dati.id)
            .then(async (result) => {
                let temp;
                if (Array.isArray(result.data)) temp = result.data;
                else temp = [result.data];

                for (const prodotto of temp) {
                    try {
                        let imgCall = await API.lizy(prodotto.nome);
                        let imgSrc = imgCall.data.immagine;
                        if (imgSrc !== null) prodotto.img = imgSrc;
                        else prodotto.img = nf;
                    } catch (error) {
                        prodotto.img = nf;
                    }
                }

                setProducts(temp);
                setLoadedProduct(true);
            })
            .catch((err) => console.log(err));
    }

    function getIndirizzi() {
        API.get("indirizzi", dati.iduser)
            .then((result) => {
                let temp;
                if (Array.isArray(result.data)) temp = result.data;
                else temp = [result.data];

                setIndirizzi({
                    spedizione: temp.filter(
                        (v) => v.id === parseInt(dati.spedizione)
                    )[0],
                    fatturazione: temp.filter(
                        (v) => v.id === parseInt(dati.fatturazione)
                    )[0],
                });
                setLoadedAddress(true);
            })
            .catch((err) => console.log(err));
    }

    return (
        <Modal
            show={vedi}
            onHide={chiudi}
            centered
            scrollable
            fullscreen={true}
            className="printModal"
        >
            <Modal.Header closeButton>
                <Modal.Title>Vista ordine</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-wrap justify-content-evenly gap-3">
                    {indirizzi?.spedizione && (
                        <AddressTable
                            indirizzo={indirizzi.spedizione}
                            nome="Indirizzo di spedizione"
                        />
                    )}
                    {indirizzi?.fatturazione && (
                        <AddressTable
                            indirizzo={indirizzi.fatturazione}
                            nome="Indirizzo di fatturazione"
                        />
                    )}
                </div>
                <div>
                    {loadedProduct ? (
                        <RiepilogoTable dati={products} />
                    ) : (
                        <PlaceholderTable qta={dati.qtaProdotti} />
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-evenly">
                <div className="text-danger cursor-pointer" onClick={chiudi}>
                    chiudi
                </div>
                <div
                    className="text-primary cursor-pointer"
                    onClick={() => {
                        window.print();
                    }}
                >
                    stampa
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default DettaglioOrdine;

const AddressTable = ({ indirizzo, nome }) => {
    if (indirizzo)
        return (
            <div>
                <h4 className="text-center">{nome}</h4>
                <Table striped={false}>
                    <Table.Tbody>
                        {Object.keys(indirizzo).map((v, i) => {
                            if (v.indexOf("id") === -1)
                                return (
                                    <tr key={i}>
                                        <td className="text-start font-weight-bold">
                                            {v.replaceAll("_", " ")}
                                        </td>
                                        <td className="text-end">
                                            {indirizzo[v]}
                                        </td>
                                    </tr>
                                );
                        })}
                    </Table.Tbody>
                </Table>
            </div>
        );
};

const RiepilogoTable = ({ dati }) => {
    return (
        <>
            {dati.map((voce, i) => (
                <div className="d-flex align-items-center gap-4 justify-content-center flex-wrap mt-3">
                    <div>
                        <img src={voce.img} width="200px" />
                    </div>
                    <div>
                        <h4>
                            {voce.nome} - {voce.tipo}
                        </h4>
                        <Table striped={false}>
                            <Table.Thead>
                                <tr>
                                    <th>Prezzo</th>
                                    <th className="text-center">Quantità</th>
                                    <th className="text-end">Totale</th>
                                </tr>
                            </Table.Thead>
                            <Table.Tbody>
                                <tr>
                                    <td>
                                        {parseFloat(voce.prezzo).toFixed(2)} €
                                    </td>
                                    <td className="text-center">{voce.qta}</td>
                                    <td className="text-end">
                                        {voce.totale} €
                                    </td>
                                </tr>
                            </Table.Tbody>
                        </Table>
                    </div>
                </div>
            ))}
        </>
    );
};

const PlaceholderTable = ({ qta }) => {
    return (
        <>
            {Array(qta)
                .fill()
                .map((v, i) => (
                    <div
                        className="d-flex align-items-center gap-4 justify-content-center flex-wrap mt-3"
                        key={i}
                    >
                        <div className="h-100">
                            <span
                                style={{ width: "200px", height: "200px" }}
                                className="d-block placeholder-wave placeholder-lg bg-secondary rounded"
                            ></span>
                        </div>
                        <div className="ms-4">
                            <h4 className="d-flex justify-content-evenly flex-nowrap gap-2">
                                <span className="d-inline-block rounded placeholder-wave col-7 placeholder-lg bg-secondary"></span>
                                -
                                <span className="d-inline-block rounded placeholder-wave col-7 placeholder-lg bg-secondary"></span>
                            </h4>
                            <Table striped={false}>
                                <Table.Thead>
                                    <tr>
                                        <th>Prezzo</th>
                                        <th className="text-center">
                                            Quantità
                                        </th>
                                        <th className="text-end">Totale</th>
                                    </tr>
                                </Table.Thead>
                                <Table.Tbody>
                                    <tr>
                                        <td>
                                            <span className="d-inline-block rounded placeholder-wave placeholder-lg bg-secondary w-100"></span>
                                        </td>
                                        <td className="text-center">
                                            <span className="d-inline-block rounded placeholder-wave placeholder-lg bg-secondary w-100"></span>
                                        </td>
                                        <td className="text-end">
                                            <span className="d-inline-block rounded placeholder-wave placeholder-lg bg-secondary w-100"></span>
                                        </td>
                                    </tr>
                                </Table.Tbody>
                            </Table>
                        </div>
                    </div>
                ))}
        </>
    );
};

PlaceholderTable.defaultProps = {
    qta: 1,
};
