/* 
    handler del carrello
    il carrello è un localStorage item

*/

const Cart = {
    /**
     * Aggiunge una voce al carrello
     * è possibile passare un array con queste chiavi e valori
     * @param {object|array} item deve essere un oggetto o un array con questo formato 
     *  {
     *      nome: "nome oggetto", 
     *      tipo: "revisionato, 
     *      nuovo, riparazione, [false] se vuoto", 
     *      qta: "int", prezzo: "float"
     *  }
     */
    aggiungi: (item) => {
        return new Promise((resolve, reject) => {
            let carrello = JSON.parse(localStorage.getItem("carrello")), back = carrello;
            if (carrello === null) carrello = [];
            if (Array.isArray(item))
                for (const voce of item) {
                    carrello.push(voce);
                }
            else carrello.push(item);
            localStorage.setItem("carrello", JSON.stringify(carrello));
            if (JSON.stringify(carrello) === localStorage.getItem("carrello")) {
                Cart.sendEvent(back, carrello)
                resolve(carrello);
            } else reject("Impossibile inserire il prodotto");
        });
    },
    lista: () => {
        return new Promise((resolve, reject) => {
            let carrello = JSON.parse(localStorage.getItem("carrello"));
            if (carrello === null) reject("carrello vuoto");
            else resolve(carrello);
        });
    },
    rimuovi: (id) => {
        return new Promise((resolve, reject) => {
            let carrello = JSON.parse(localStorage.getItem("carrello")), back = carrello;
            if (carrello === null) reject("carrello vuoto");
            carrello.splice(id, 1);
            localStorage.setItem("carrello", JSON.stringify(carrello));
            if (carrello !== JSON.parse(localStorage.getItem("carrello"))) {
                Cart.sendEvent(back, carrello)
                resolve(carrello);
            } else reject("Non sono riuscito a cancellare la voce");
        });
    },
    sendEvent: (old, newObj) => {
        const event = new StorageEvent("storage", {
            key: "cerrello",
            old,
            newObj
        });

        window.dispatchEvent(event);
    }
};

export default Cart;
