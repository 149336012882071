/* 
    Semplice funzione condivisa che ritorna true se tema scuro e false se tema chiaro
*/

const IsDark = () => {
    let auto = localStorage.getItem("tema_auto"),
    scuro = localStorage.getItem("tema_scuro");
    
    if (scuro == "true")
        return true;
    else {
        if (auto == "true") {
            if (window.matchMedia('(prefers-color-scheme: dark)').matches)
                return true;
            else
                return false;
        } else return false;
    }
}

export default IsDark