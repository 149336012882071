import React, { useEffect } from "react";
import TopHomePart from "../components/home/TopHomePart";
import { useNavigate } from "react-router-dom";
import API from "../API";
import toast from "react-hot-toast"
import BottomPart from "../components/home/BottomPart";

const Home = () => {
    const navigate = useNavigate()

    useEffect(() => {
        firstRunChecker()
    }, [])
    
    function firstRunChecker() {
        let userId = JSON.parse(localStorage.getItem("cliente")).dati.id;
        if (userId) {
            API.get("indirizzi", userId).catch(() => {
                toast.error("Inserisci nome, cognome ed indirizzo per utilizzare l'applicazione");
                navigate("/users")
            })
        }
    }

    return (
        <section className="container-fluid p-3 text-center">
            <TopHomePart />

            <BottomPart />
        </section>
    );
};

export default Home;
