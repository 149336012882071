import React, { useEffect, useState } from "react";
import API from "../../API";
import { toast } from "react-hot-toast";
import Button from "../inputs/Button";
import ModalAggIndirizzo from "./ModalAggIndirizzo";
import IndirizzoCard from "./IndirizzoCard";

const Indirizzi = () => {
    const [indirizzi, setIndirizzi] = useState([]);
    const [modalV, setModalV] = useState(false);
    const [indirizzoEdit, setIndirizzoEdit] = useState({});

    useEffect(() => {
        getIndirizzi();
    }, []);

    const getIndirizzi = () => {
        if (navigator.onLine) {
            const user = JSON.parse(localStorage.getItem("cliente")).dati;
            API.get("indirizzi", user.id)
                .then((data) => {
                    if (Array.isArray(data.data))
                        setIndirizzi(data.data);
                    else
                        setIndirizzi([data.data])
                })
                .catch((err) => {
                    console.error(err);
                });
        } else toast.error("Nessuna connessione al server");
    };

    /* d-flex gap-4 flex-wrap */
    return (
        <section className="container-fluid">
            <ModalAggIndirizzo
                vedi={modalV}
                chiudi={() => {
                    setModalV(false);
                    setIndirizzoEdit({});
                }}
                dati={indirizzoEdit}
                update={getIndirizzi}
            />
            <div className="mb-4 d-flex justify-content-end">
                <Button
                    onClick={() => {
                        setIndirizzoEdit({});
                        setModalV(true);
                    }}
                    className="flex-md-grow-0 flex-grow-1"
                >
                    Inserisci indirizzo
                </Button>
            </div>
            <div className="d-flex flex-wrap gap-4 justify-content-center justify-content-md-start">
                {indirizzi.map((val, i) => (
                    <IndirizzoCard
                        key={i}
                        dati={val}
                        onClick={() => {
                            setIndirizzoEdit(val)
                            setModalV(true)
                        }}
                    />
                ))}
            </div>
        </section>
    );
};

export default Indirizzi;
