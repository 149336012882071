import React, { useState, useEffect } from "react";
import Table from "../Table";
import Cart from "../../Cart";

const RiepilogoTable = ({ setError }) => {
    const [totale, setTotale] = useState(0.0);
    const [iva, setIva] = useState(0.0);
    const [totaleImp, setTotaleImp] = useState(0.0);
    const [voci, setVoci] = useState([]);

    useEffect(() => {
        getLista();
    }, []);

    const floater = val => parseFloat(val).toFixed(2)

    async function getLista() {
        try {
            let tempCart = await Cart.lista(), tot = 0.0;
            if (tempCart.length < 1) {
                //throw new Error("Il carrello è vuoto");
                setError("Il carrello è vuoto");
                return false;
            }
            setVoci(tempCart);
            for (const voce of tempCart) {
                tot += parseFloat(voce.prezzo * voce.qta)
            }
            //parte della spedizione
            tot = parseFloat(tot+20);
            setTotale(floater(tot))
            setIva(floater(tot * 0.22))
            setTotaleImp(floater((tot + (tot * 0.22))))
        } catch (error) {
            setError(`Errore nell'ottenimento dei prodotti dal carrello (${error})`)
        }
    }

    return (
        <Table striped={false}>
            <Table.Thead>
                <tr>
                    <th className="text-start">Codice</th>
                    <th>Tipo</th>
                    <th>Netto</th>
                    <th>Qta</th>
                    <th className="text-end">Prezzo</th>
                </tr>
            </Table.Thead>
            <Table.Tbody>
                {voci.map((voce, i) => (
                    <tr key={i}>
                        <td className="text-start">{voce.nome}</td>
                        <td>{voce.tipo}</td>
                        <td>{voce.prezzo}</td>
                        <td>{voce.qta}</td>
                        <td className="text-end">{parseFloat(voce.prezzo * voce.qta).toFixed(2)} €</td>
                    </tr>
                ))}
            </Table.Tbody>
            <Table.Tfoot>
                <tr>
                    <td colSpan={2} className="text-start font-weight-bold">Spedizione</td>
                    <td colSpan={3} className="text-end">20.00 €</td>
                </tr>
                <tr>
                    <td colSpan={2} className="text-start font-weight-bold">Totale</td>
                    <td colSpan={3} className="text-end">{totale} €</td>
                </tr>
                <tr>
                    <td colSpan={2} className="text-start font-weight-bold">Iva</td>
                    <td colSpan={3} className="text-end">{iva} €</td>
                </tr>
                <tr>
                    <td colSpan={2} className="text-start font-weight-bold">Totale con Iva</td>
                    <td colSpan={3} className="text-end">{totaleImp} €</td>
                </tr>
            </Table.Tfoot>
        </Table>
    );
};

export default RiepilogoTable;
