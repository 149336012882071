import React from "react";
import Table from "./Table";

const PlaceholderTables = ({ col, row }) => {
    return (
        <Table className="w-100" striped={false}>
            <Table.Thead>
                <tr>
                    {Array(col)
                        .fill()
                        .map((i) => (
                            <td key={i} className="text-center">
                                <span className="placeholder-wave mx-auto col-6 placeholder-lg bg-secondary d-block rounded"></span>
                            </td>
                        ))}
                </tr>
            </Table.Thead>
            <Table.Tbody>
                {Array(row)
                    .fill()
                    .map((r) => (
                        <tr key={r}>
                            {Array(col)
                                .fill()
                                .map((c) => (
                                    <td key={c}>
                                        <span className="placeholder-wave mx-auto col-4 placeholder-lg bg-secondary d-block rounded"></span>
                                    </td>
                                ))}
                        </tr>
                    ))}
            </Table.Tbody>
        </Table>
    );
};

export default PlaceholderTables;

PlaceholderTables.defaultProps = {
    col: 4,
    row: 3,
};

const styles = {};
