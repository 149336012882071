import React, { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const variants = {
    closed: {
        y: "100%",
        opacity: 0,
        transition: {
            duration: 0.4,
            delay: 0.3,
        },
    },
    open: {
        y: "0%",
        opacity: 1,
        transition: {
            duration: 0.4,
            delay: 0.3,
        },
    },
    hover: {
        scale: 1.05,
    },
    clicked: {
        scale: 0.95,
    },
};

const Menu = ({ vedi, esci }) => {
    const navigate = useNavigate();

    let user;
    if (localStorage.getItem("cliente") !== null)
        user = JSON.parse(localStorage.getItem("cliente")).dati.hash;
    else user = false;

    const moveTo = (pagina) => {
        navigate(`/${pagina}`);
        esci();
    };

    return (
        <Offcanvas show={vedi} onHide={esci} className="bg-primary text-white">
            <Offcanvas.Header closeButton className="closeButtonWhite">
                <Offcanvas.Title>Menu</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <section className="d-flex flex-column h-100 align-items-center justify-content-center">
                    <div className="flex-fill d-flex justify-content-center gap-4 align-items-center w-100 flex-column">
                        {user ===
                            "$2y$10$EW.nf22Tikk2uel6hUhXUOI42.dLpcWCQgMvLHdudPaDXx.mdQFgC" && (
                            <Voce att={vedi} onClick={() => moveTo("admin")}>
                                Admin Panel
                            </Voce>
                        )}
                        <Voce att={vedi} onClick={() => moveTo("")}>
                            Home
                        </Voce>
                        <Voce att={vedi} onClick={() => moveTo("ordini")}>
                            Storico ordini
                        </Voce>
                    </div>
                    <div className="w-100 p-2">
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.9 }}
                            className={`border-0 bg-indian text-white rounded shadow ps-3 pe-3 pt-2 pb-2 w-100`}
                            onClick={() => {
                                esci();
                                navigate("/settings");
                            }}
                        >
                            impostazioni
                        </motion.button>
                    </div>
                </section>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default Menu;

const arrowVariants = {
    hidden: {
        x: "500%",
    },
    show: {
        x: "0",
    },
};

const Voce = ({ att, children, onClick }) => {
    const [vediF, setVediF] = useState(false);

    return (
        <motion.div
            variants={variants}
            animate={att ? "open" : "closed"}
            initial="closed"
            className="bg-indian text-white shadow rounded w-100 p-3 d-flex justify-contents-between overflow-hidden"
            whileHover="hover"
            whileTap="clicked"
            onMouseEnter={() => setVediF(true)}
            onMouseLeave={() => setVediF(false)}
            onTap={onClick}
        >
            <p className="mb-0 flex-fill">{children}</p>
            <motion.span
                className="material-symbols-outlined"
                variants={arrowVariants}
                initial="hidden"
                animate={vediF ? "show" : "hidden"}
            >
                arrow_forward_ios
            </motion.span>
        </motion.div>
    );
};
