import React from "react";
import taken from "../../asset/taken.svg";
import Container from "../Container";

const ArticoloFail = () => {
    return (
        <Container className="d-flex">
            <div>
                <img src={taken} style={styles.img} />
            </div>
            <div>
                <h1 className="text-primary">Articolo non trovato</h1>
                <p style={styles.text}>
                    L'articolo che stai cercando non è stato trovato, forse è
                    stato spostato.
                </p>
                <p style={styles.text}>
                    Se sei sicuro di essere nel posto giusto, contattaci per
                    risolvere il problema.
                </p>
            </div>
        </Container>
    );
};

export default ArticoloFail;

const styles = {
    img: {
        maxWidth: "500px",
        width: "100%",
    },
    text: {
        fontSize: "25px",
    },
};
