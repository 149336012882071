import React from "react";
import { motion } from "framer-motion";

const Button = (props) => {
    const variant = {
        primary: "bg-primary text-white",
        indian: "bg-indian text-white",
        secondary: "bg-secondary text-white",
    };

    return (
        <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className={`border-0 ${
                variant[props.variant]
            } ps-3 pt-2 pe-3 pb-2 shadow rounded ${
                props.className && props.className
            } ${
                props.disabled && variant.secondary
            }`}
            onClick={() => {
                if (typeof props.onClick !== "undefined") props.onClick();
            }}
            name={props.name}
            disabled={props.disabled}
        >
            {props.children}
        </motion.button>
    );
};

export default Button;

Button.defaultProps = {
    variant: "primary",
    name: "PlaceholderButton",
    disabled: false,
};
