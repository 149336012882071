import React, { useEffect, useState } from "react";
import { Modal, ProgressBar } from "react-bootstrap";
import { toast } from "react-hot-toast";
import API from "../../API";
import Cart from "../../Cart";
import { useNavigate } from "react-router-dom";

const Carica = ({ vedi, chiudi, dati }) => {
    const [perc, setPerc] = useState(0);
    const [testo, setTesto] = useState("");
    const [variant, setVariant] = useState("primary");
    const [completato, setCompletato] = useState(false);

    const navigator = useNavigate();

    useEffect(() => {
        if (vedi) sendOrder();
    }, [vedi]);

    useEffect(() => {
        if (completato) {
            toast.success("Ordine inviato correttamente");
            setTimeout(() => {
                navigator("/");
            }, 3000);
        }
    }, [completato]);

    async function sendOrder() {
        try {
            setPerc(25);
            setTesto("Invio dati");
            let userId = JSON.parse(localStorage.getItem("cliente")).dati.id,
                orderId;
            await new Promise((resolve, reject) => {
                let invia = { ...dati };

                //sistema migliore toLocaleString e filtrare poi il risultato
                invia.iduser = userId;
                invia.data =
                    new Date().toISOString().substring(0, 10) +
                    " " +
                    new Date().toLocaleTimeString("it-IT");
                //da cambiare ora con locale date string
                API.post("ordini", invia)
                    .then(() => resolve())
                    .catch(() => reject("Errore invio dati"));
            });

            setPerc(50);
            setTesto("Ottengo order ID");
            await new Promise((resolve, reject) => {
                API.get("lastOrderId", userId)
                    .then((result) => {
                        orderId = result.data.last_order_id;
                        resolve();
                    })
                    .catch(() => {
                        orderId = 0;
                        resolve();
                    });
            });

            setPerc(75);
            setTesto("Invio il carrello");
            await new Promise(async (resolve, reject) => {
                try {
                    const carrello = await Cart.lista();
                    for (const articolo of carrello) {
                        let invio = articolo;
                        invio.id_user = userId;
                        invio.id_order = orderId;
                        invio.totale = parseFloat(
                            invio.prezzo * invio.qta
                        ).toFixed(2);
                        await API.post("prodotti", invio);
                        await Cart.rimuovi(0);
                    }
                    resolve();
                } catch (error) {
                    reject(error);
                }
            });

            setPerc(100);
            setTesto("Invio email di conferma");
            await new Promise((resolve) =>
                setTimeout(async () => {
                    try {
                        const formData = new FormData();
                        formData.append("id_order", orderId);
                        await fetch(
                            `https://fornitori.officinaveneziani.com/api/mailsend.php`,
                            {
                                method: "POST",
                                headers: {
                                    Userhash: API.getUser(),
                                },
                                body: formData,
                            }
                        );
                        resolve();
                    } catch (error) {
                        toast.error(
                            "Non sono riuscito ad inviare l'email, contattaci"
                        );
                        resolve();
                    }
                }, 2000)
            );

            setCompletato(true);
        } catch (error) {
            toast.error(error);
            setVariant("indian");
            setTimeout(() => {
                chiudi();
            }, 2500);
        }
    }

    return (
        <Modal
            centered
            show={vedi}
            onHide={chiudi}
            keyboard={false}
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title>Invio Ordine</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!completato ? (
                    <ProgressBar
                        variant={variant}
                        style={styles.progress}
                        now={perc}
                        label={testo}
                    />
                ) : (
                    <h4 className="text-success text-center">Ordine salvato</h4>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default Carica;

const styles = {
    progress: {
        height: "40px",
    },
};
