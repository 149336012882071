import React from "react";
import IsDark from "../IsDark";

const Container = ({
    children,
    className,
    tipo,
    style,
    rounded,
    shadow,
    padding,
}) => {
    return (
        <div
            className={`${IsDark() ? "bg-dark" : "bg-white"} ${
                rounded && "rounded"
            } ${shadow && "shadow"} ${padding && padding} ${
                className && className
            } ${tipo}`}
            style={style}
        >
            {children}
        </div>
    );
};

export default Container;

Container.defaultProps = {
    className: false,
    tipo: "container",
    style: {},
    rounded: true,
    shadow: true,
    padding: "p-2",
};
