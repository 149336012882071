import React, { useState, useEffect } from "react";
import Menu from "./Menu";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Badge, Popover } from "react-bootstrap";
import { motion } from "framer-motion";
import CarrelloRapido from "./CarrelloRapido";
import Button from "./inputs/Button";

const Header = () => {
    const [vediMenu, setVediMenu] = useState(false);
    const [voci, setVoci] = useState(false); //false se vuoto
    const [carr, setCarr] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        window.addEventListener("storage", checkStorage);
        checkStorage();

        return () => {
            window.removeEventListener("storage", checkStorage);
        };
    }, []);

    function checkStorage() {
        let vociTemp = JSON.parse(localStorage.getItem("carrello"));
        if (vociTemp === null) {
            setVoci(false);
        } else {
            setVoci(vociTemp.length);
            if (Array.isArray(vociTemp)) setCarr(vociTemp);
            else setCarr([vociTemp]);
        }
    }

    const goToUsers = () => navigate("/users");

    return (
        <React.Fragment>
            <header className="w-100 mb-4 p-3 bg-primary text-white d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <span
                        className="material-symbols-outlined cursor-pointer me-2"
                        onClick={() => setVediMenu(true)}
                    >
                        menu
                    </span>
                    <h5
                        className="mb-0 cursor-pointer"
                        onClick={() => navigate("/")}
                    >
                        Fornitori
                    </h5>
                </div>
                <div className="d-flex justify-content-evenly gap-4 align-items-center">
                    <span
                        className="material-symbols-outlined cursor-pointer"
                        onClick={goToUsers}
                    >
                        person
                    </span>

                    <OverlayTrigger
                        trigger="click"
                        placement="bottom"
                        rootClose={true}
                        overlay={
                            <Popover id="cartRapid" style={styles.cart}>
                                <Popover.Header as="h3">
                                    Carrello
                                </Popover.Header>
                                <Popover.Body className="p-1">
                                    <CarrelloRapido voci={carr} />
                                </Popover.Body>
                                <div className="d-flex">
                                    {voci > 0 && (
                                        <Button
                                            variant="indian"
                                            className="m-2 flex-fill"
                                            onClick={() =>
                                                navigate("/carrello")
                                            }
                                        >
                                            Carrello
                                        </Button>
                                    )}
                                </div>
                            </Popover>
                        }
                    >
                        <motion.div
                            className="d-flex align-items-center bg-indian p-2 rounded cursor-pointer position-relative"
                            whileHover={{
                                scale: 1.05,
                            }}
                            whileTap={{
                                scale: 0.95,
                            }}
                        >
                            <span className="material-symbols-outlined">
                                shopping_bag
                            </span>
                            {voci > 0 && (
                                <Badge
                                    bg="badge"
                                    className="position-absolute top-0 start-100 translate-middle"
                                >
                                    {voci}
                                </Badge>
                            )}
                        </motion.div>
                    </OverlayTrigger>
                </div>
            </header>
            <Menu vedi={vediMenu} esci={() => setVediMenu(false)} />
        </React.Fragment>
    );
};

export default Header;

const styles = {
    cart: {
        minWidth: "350px",
    },
};
