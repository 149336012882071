import React, { useEffect, useState } from "react";
import Table from "../Table";
import { motion } from "framer-motion";
import IsDark from "../../IsDark";
import FloatingSelect from "../inputs/FloatingSelect";
import ConfirmSlider from "../inputs/ConfirmSlider";
import Cart from "../../Cart";
import { toast } from "react-hot-toast";
import Switch from "../inputs/Switch";

const variants = {
    closed: {
        opacity: 0,
        bottom: "-1000%",
    },
    open: {
        opacity: 1,
        bottom: 0,
    },
};

//da aggiungere: carcassa
const AdderModal = ({ vedi, chiudi, prezzi, qta, carcasse }) => {
    const [qtaSel, setQtaSel] = useState({
        revisionato: [],
        revisionato_bosch: [],
        nuovo: [],
    });
    const [selVals, setSelVals] = useState({
        revisionato: 0,
        revisionato_bosch: 0,
        nuovo: 0,
    });
    const [enabled, setEnabled] = useState(false);
    const [reso, setReso] = useState(true);

    /* useEffect(() => {
        if (!vedi) {
            setQtaSel({
                revisionato: [],
                revisionato_bosch: [],
                nuovo: [],
            });
        }
    }, [vedi]); */

    useEffect(() => {
        if (qta) qtaSelect();
    }, [qta]);

    useEffect(() => {
        if (
            selVals.revisionato > 0 ||
            selVals.revisionato_bosch > 0 ||
            selVals.nuovo > 0
        )
            setEnabled(true);
        else setEnabled(false);
    }, [selVals]);

    async function qtaSelect() {
        //da craere rev -> [qta: x]
        let qtaWork = {
            revisionato:
                qta.Da_revisionare + qta.In_lavorazione + qta.Revisionato,
            revisionato_bosch: qta.Revisionato_Bosch,
            nuovo: qta.Nuovo,
        };

        let selector = {
            revisionato: await compileArr(qtaWork.revisionato),
            revisionato_bosch: await compileArr(qtaWork.revisionato_bosch),
            nuovo: await compileArr(qtaWork.nuovo),
        };

        setQtaSel(selector);
    }

    const compileArr = (numero) => {
        return new Promise((resolve) => {
            if (numero === null || numero < 1) {
                resolve([{ qta: 0 }]);
            } else {
                let returner = [];
                for (let i = 0; i <= numero; i++) {
                    returner.push({ [i]: i });
                }
                resolve(returner);
            }
        });
    };

    //aggiungo al carrello, manca carcassa
    async function cartAdd() {
        //qta -> codice
        try {
            if (selVals.revisionato > 0) {
                if (reso === false && carcasse.revisionato)
                    await Cart.aggiungi({
                        nome: `${qta.codice} senza reso`,
                        tipo: "revisionato",
                        qta: selVals.revisionato,
                        prezzo: parseFloat(
                            parseFloat(prezzi.revisionato) +
                                parseFloat(carcasse.revisionato)
                        ).toFixed(2),
                    });
                else
                    await Cart.aggiungi({
                        nome: qta.codice,
                        tipo: "revisionato",
                        qta: selVals.revisionato,
                        prezzo: parseFloat(prezzi.revisionato).toFixed(2),
                    });
            }
            if (selVals.revisionato_bosch > 0) {
                if (reso === false && carcasse.revisionato_bosch)
                    await Cart.aggiungi({
                        nome: `${qta.codice} senza reso`,
                        tipo: "revisionato_bosch",
                        qta: selVals.revisionato_bosch,
                        prezzo: parseFloat(
                            prezzi.revisionato_bosch +
                                carcasse.revisionato_bosch
                        ).toFixed(2),
                    });
                else
                    await Cart.aggiungi({
                        nome: qta.codice,
                        tipo: "revisionato_bosch",
                        qta: selVals.revisionato_bosch,
                        prezzo: parseFloat(prezzi.revisionato_bosch).toFixed(2),
                    });
            }
            if (selVals.nuovo > 0) {
                if (reso === false && carcasse.nuovo)
                    await Cart.aggiungi({
                        nome: `${qta.codice} senza reso`,
                        tipo: "nuovo",
                        qta: selVals.nuovo,
                        prezzo: parseFloat(
                            prezzi.nuovo + carcasse.nuovo
                        ).toFixed(2),
                    });
                else
                    await Cart.aggiungi({
                        nome: qta.codice,
                        tipo: "nuovo",
                        qta: selVals.nuovo,
                        prezzo: parseFloat(prezzi.nuovo).toFixed(2),
                    });
            }

            toast.success("Carrello aggiornato");
            chiudi();
        } catch (error) {
            toast.error(error);
        }
    }

    return (
        <motion.div
            animate={vedi ? "open" : "closed"}
            variants={variants}
            className={`position-absolute w-100 m-0 start-0 ${
                IsDark() ? "bg-dark" : "bg-white"
            } p-2`}
            style={styles.offcanvas}
        >
            <div className="d-flex justify-content-between p-2">
                <h4>Quantità</h4>
                <span
                    className="material-symbols-outlined cursor-pointer align-middle"
                    onClick={chiudi}
                >
                    close
                </span>
            </div>
            <div>
                <Table striped={false}>
                    {/* <Table.Thead>
                        <tr>
                            <th>Tipo</th>
                            <th className="text-end">Netto</th>
                            <th className="text-center">Qta</th>
                            <th className="text-end">Tot.netto</th>
                            <th className="text-end">Tot.imponibile</th>
                        </tr>
                    </Table.Thead> */}
                    <Table.Tbody>
                        <tr className="align-middle align-items-center">
                            <td className="text-start">Revisionato</td>
                            <td className="text-end">{prezzi.revisionato}</td>
                            <td>
                                <FloatingSelect
                                    classes="text-center mx-auto"
                                    margin={false}
                                    style={styles.input}
                                    id="qta_rev"
                                    name="qta_rev"
                                    label="Qta"
                                    valori={qtaSel.revisionato}
                                    onChange={(v) =>
                                        setSelVals((prev) => ({
                                            ...prev,
                                            revisionato: parseInt(
                                                v.target.value
                                            ),
                                        }))
                                    }
                                />
                            </td>
                            <td className="text-end">
                                {parseFloat(
                                    prezzi.revisionato * selVals.revisionato
                                ).toFixed(2)}
                            </td>
                            {/* <td className="text-end">
                                {parseFloat(
                                    prezzi.revisionato * selVals.revisionato +
                                        prezzi.revisionato *
                                            selVals.revisionato *
                                            0.22
                                ).toFixed(2)}
                            </td> */}
                        </tr>
                        <tr className="align-middle align-items-center">
                            <td className="text-start">Revisionato Bosch</td>
                            <td className="text-end">
                                {prezzi.revisionato_bosch}
                            </td>
                            <td>
                                <FloatingSelect
                                    classes="text-center mx-auto"
                                    margin={false}
                                    style={styles.input}
                                    id="qta_rev_bosch"
                                    name="qta_rev_bosch"
                                    label="Qta"
                                    valori={qtaSel.revisionato_bosch}
                                    onChange={(v) =>
                                        setSelVals((prev) => ({
                                            ...prev,
                                            revisionato_bosch: parseInt(
                                                v.target.value
                                            ),
                                        }))
                                    }
                                />
                            </td>
                            <td className="text-end">
                                {parseFloat(
                                    prezzi.revisionato_bosch *
                                        selVals.revisionato_bosch
                                ).toFixed(2)}
                            </td>
                            {/* <td className="text-end">
                                {parseFloat(
                                    prezzi.revisionato_bosch *
                                        selVals.revisionato_bosch +
                                        prezzi.revisionato_bosch *
                                            selVals.revisionato_bosch *
                                            0.22
                                ).toFixed(2)}
                            </td> */}
                        </tr>
                        <tr className="align-middle align-items-center">
                            <td className="text-start">Nuovo</td>
                            <td className="text-end">{prezzi.nuovo}</td>
                            <td>
                                <FloatingSelect
                                    classes="text-center mx-auto"
                                    margin={false}
                                    style={styles.input}
                                    id="qta_nuovo"
                                    name="qta_nuovo"
                                    label="Qta"
                                    valori={qtaSel.nuovo}
                                    onChange={(v) =>
                                        setSelVals((prev) => ({
                                            ...prev,
                                            nuovo: parseInt(v.target.value),
                                        }))
                                    }
                                />
                            </td>
                            <td className="text-end">
                                {parseFloat(
                                    prezzi.nuovo * selVals.nuovo
                                ).toFixed(2)}
                            </td>
                            {/* <td className="text-end">
                                {parseFloat(
                                    prezzi.nuovo * selVals.nuovo +
                                        prezzi.nuovo * selVals.nuovo * 0.22
                                ).toFixed(2)}
                            </td> */}
                        </tr>
                    </Table.Tbody>
                </Table>
                {/* parte delle carcasse */}
                {(carcasse.revisionato ||
                    carcasse.revisionato_bosch ||
                    carcasse.nuovo) && (
                    <TableCarcasser
                        selected={selVals}
                        carcasse={carcasse}
                        exportReso={setReso}
                    />
                )}
            </div>
            <div className="text-center">
                <ConfirmSlider disabled={!enabled} success={cartAdd} />
                <sup className="text-indian mb-3">
                    scorri per confermare l'ordine
                </sup>
            </div>
        </motion.div>
    );
};

export default AdderModal;

AdderModal.defaultProps = {
    vedi: false,
    qta: false,
};

const styles = {
    input: {
        maxWidth: "80px",
    },
    offcanvas: {
        borderRadius: "25px 25px 0 0",
        zIndex: "150",
    },
};

const TableCarcasser = ({ selected, carcasse, exportReso }) => {
    const [reso, setReso] = useState(true);

    useEffect(() => {
        exportReso(reso);
    }, [reso]);

    return (
        <div>
            <div className="d-flex justify-content-between align-middle align-items-center">
                <div>
                    <p className="mb-0">Rendo le mie carcasse</p>
                    <sup className="text-secondary">
                        Devi inviarci il tuo materiale guasto
                    </sup>
                </div>
                <Switch
                    name="attiva_carcasse"
                    id="attiva_carcasse"
                    onChange={setReso}
                    checked={reso}
                />
            </div>
            <Table striped={false}>
                <Table.Tbody>
                    {carcasse.revisionato && (
                        <tr>
                            <td className="text-start">Revisionato</td>
                            <td className="text-end">
                                {!reso
                                    ? parseFloat(
                                          selected.revisionato *
                                              carcasse.revisionato
                                      ).toFixed(2)
                                    : 0.0}
                            </td>
                        </tr>
                    )}
                    {carcasse.revisionato_bosch && (
                        <tr>
                            <td className="text-start">Revisionato Bosch</td>
                            <td className="text-end">
                                {!reso
                                    ? parseFloat(
                                          selected.revisionato_bosch *
                                              carcasse.revisionato_bosch
                                      ).toFixed(2)
                                    : 0.0}
                            </td>
                        </tr>
                    )}
                    {carcasse.nuovo && (
                        <tr>
                            <td className="text-start">Nuovo</td>
                            <td className="text-end">
                                {!reso
                                    ? parseFloat(
                                          selected.nuovo * carcasse.nuovo
                                      ).toFixed(2)
                                    : 0.0}
                            </td>
                        </tr>
                    )}
                </Table.Tbody>
            </Table>
        </div>
    );
};
