import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import IsDark from "../../IsDark";
import ConfirmSlider from "../inputs/ConfirmSlider";
import FloatingInput from "../inputs/FloatingInput";
import Cart from "../../Cart";
import { toast } from "react-hot-toast";

const variants = {
    closed: {
        opacity: 0,
        bottom: "-500%",
    },
    open: {
        opacity: 1,
        bottom: 0,
    },
};

const RepairerModal = ({ vedi, chiudi, codice }) => {
    const [disabled, setDisabled] = useState(false);
    const [quanti, setQuanti] = useState(0);

    useEffect(() => {
        setDisabled(quanti < 1 ? true : false);
    }, [quanti]);

    async function success() {
        try {
            await Cart.aggiungi({
                nome: codice,
                tipo: "riparazione",
                qta: quanti,
                prezzo: 0,
            });
            toast.success("Carrello aggiornato")
            chiudi()
        } catch (error) {
            toast.error(error)
        }
    }

    return (
        <motion.div
            animate={vedi ? "open" : "closed"}
            variants={variants}
            className={`position-absolute w-100 m-0 start-0 ${
                IsDark() ? "bg-dark" : "bg-white"
            } p-2`}
            style={styles.offcanvas}
        >
            <div className="d-flex justify-content-between p-2">
                <h4>Quantità</h4>
                <span
                    className="material-symbols-outlined cursor-pointer align-middle"
                    onClick={chiudi}
                >
                    close
                </span>
            </div>
            <div>
                <p className="mb-0">
                    Invio del vostro materiale per la riparazione.
                </p>
                <p>
                    Verrà fatto un preventivo corretto al ricevimento del vostro
                    materiale.
                </p>
                <FloatingInput
                    name="qta"
                    id="qta"
                    label="Quantità"
                    type="number"
                    onChange={setQuanti}
                />
            </div>
            <div className="text-center">
                <ConfirmSlider disabled={disabled} success={success} />
                <sup className="mb-3 text-indian">scorri per confermare l'ordine</sup>
            </div>
        </motion.div>
    );
};

export default RepairerModal;

RepairerModal.defaultProps = {
    vedi: false,
    qta: false,
};

const styles = {
    input: {
        maxWidth: "80px",
    },
    offcanvas: {
        borderRadius: "25px 25px 0 0",
        zIndex: "150",
    },
};
