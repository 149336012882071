import React, { useState, useEffect, useRef } from "react";
import FloatingInput from "../inputs/FloatingInput";
import Button from "../inputs/Button";
import API from "../../API";
import { toast } from "react-hot-toast";

const rules = {
    nome: {
        length: 4,
        include: "",
    },
    cognome: {
        length: 4,
        include: "",
    },
    email: {
        length: 6,
        include: "@",
    },
    password: {
        length: 8,
        include: "",
    },
};

//devo impostare nella verifica is-valid is-invalid per la verifica dei dati
const Infos = () => {
    const [valido, setValido] = useState({
        nome: null,
        cognome: null,
        email: null,
        password: null,
    });
    const [valori, setValori] = useState({
        nome: null,
        cognome: null,
        email: null,
    });
    const [pass, setPass] = useState("");
    const [passVer, setPassVer] = useState("");
    const [disab, setDisab] = useState(true);

    const inputs = useRef();

    useEffect(() => {
        compileInput();
        //checkValidInputs();
    }, []);

    useEffect(() => {
        if (pass === passVer && pass.length >= 8) setDisab(false);
        else setDisab(true);
    }, [pass, passVer]);

    const compileInput = () => {
        const dati = JSON.parse(localStorage.getItem("cliente")).dati;
        setValori({
            nome: dati.nome,
            cognome: dati.cognome,
            email: dati.email,
        });
    };

    //controlla all'avvio se i dati non sono corretti
    const checkValidInputs = () => {
        inputs.current.querySelectorAll("input").forEach((val) => {
            if (rules[val.name]) {
                if (val.value.length >= rules[val.name].length) {
                    if (val.value.indexOf(rules[val.name].include) > -1)
                        setValido((prevState) => ({
                            ...prevState,
                            [val.name]: null,
                        }));
                    else
                        setValido((prevState) => ({
                            ...prevState,
                            [val.name]: false,
                        }));
                } else
                    setValido((prevState) => ({
                        ...prevState,
                        [val.name]: false,
                    }));
            }
        });
    };

    const handleConfirm = () => {
        let check = Object.values(valido).filter((v) => v === false).length;
        if (check < 1) {
            //ottengo i dati
            let send = {};
            for (const input of inputs.current.querySelectorAll("input")) {
                send[input.name] = input.value;
            }
            const { id, hash } = JSON.parse(
                localStorage.getItem("cliente")
            ).dati;
            API.changeUser(send, id)
                .then((result) => {
                    if (result.status) {
                        localStorage.setItem(
                            "cliente",
                            JSON.stringify({
                                data: new Date().toISOString().substring(0, 10),
                                dati: {
                                    nome: send.nome,
                                    cognome: send.cognome,
                                    email: send.email,
                                    id: id,
                                    hash: hash,
                                },
                            })
                        );
                        toast.success("Dati inseriti con successo");
                    }
                })
                .catch((err) => {
                    toast.error(`Errore: ${err}`);
                });
        } else toast.error("Qualche dato non risulta corretto");
    };

    //controlla se input è valido o meno
    const handleValid = (nome, valore) => {
        if (rules[nome]) {
            if (valore.length >= rules[nome].length) {
                if (valore.indexOf(rules[nome].include) >= 0)
                    setValido((prevState) => ({ ...prevState, [nome]: null }));
                else
                    setValido((prevState) => ({ ...prevState, [nome]: false }));
            } else setValido((prevState) => ({ ...prevState, [nome]: false }));
        }
    };

    return (
        <section ref={inputs}>
            <div className="d-flex gap-4 flex-wrap">
                <FloatingInput
                    name="nome"
                    type="text"
                    label="Nome"
                    placeholder="Nome"
                    id="nome"
                    className="flex-fill"
                    onChange={handleValid.bind(this, "nome")}
                    valid={valido.nome}
                    invalidText={"Inserisci un nome"}
                    defaultValue={valori.nome}
                />
                <FloatingInput
                    name="cognome"
                    type="text"
                    label="Cognome"
                    placeholder="cognome"
                    id="cognome"
                    className="flex-fill"
                    onChange={handleValid.bind(this, "cognome")}
                    valid={valido.cognome}
                    invalidText={"Inserisci un cognome"}
                    defaultValue={valori.cognome}
                />
            </div>
            <FloatingInput
                name="email"
                type="email"
                label="Email"
                placeholder="email"
                id="email"
                className="flex-fill"
                onChange={handleValid.bind(this, "email")}
                valid={valido.email}
                invalidText={"Non sembra essere un email valida"}
                defaultValue={valori.email}
            />
            <div className="d-flex gap-4 flex-wrap">
                <div className="flex-fill">
                    <FloatingInput
                        name="password"
                        type="password"
                        label="Password"
                        placeholder="password"
                        id="password"
                        onChange={(val) => {
                            handleValid("password", val);
                            setPass(val);
                        }}
                        valid={valido.password}
                        invalidText={
                            "La password deve avere almeno 8 caratteri"
                        }
                    />
                </div>
                <div className="flex-fill">
                    <FloatingInput
                        name="ripeti_password"
                        type="password"
                        label="Ripeti Password"
                        placeholder="ripeti_password"
                        id="ripeti_password"
                        onChange={setPassVer}
                        valid={pass === passVer ? null : false}
                        invalidText={"Le password non coincidono"}
                    />
                </div>
            </div>
            <p>per apportare modifiche è richiesta la password</p>
            <div>
                <Button
                    variant="primary"
                    name="conferma"
                    onClick={handleConfirm}
                    disabled={disab}
                >
                    Aggiorna
                </Button>
            </div>
        </section>
    );
};

export default Infos;
