import React from "react";
import IsDark from "../../IsDark";
import Notizia from "../notizie/NotiziaBlock";

const News = () => {
    return (
        <div
            className={`${
                IsDark() ? "bg-dark" : "bg-white"
            } shadow rounded p-2 flex-fill overflow-y-auto`}
            style={styles.notizie}
        >
            <h4 className="text-start">Notizie</h4>
            <div className="p-2">
                {notContainer.map((val, i) => (
                    <Notizia
                        id={val.id}
                        key={i}
                        titolo={val.titolo}
                        descrizione={val.descrizione}
                    />
                ))}
            </div>
        </div>
    );
};

export default News;

const styles = {
    notizie: {
        maxHeight: "400px",
    },
};

const notContainer = [
    {
        id: 0,
        titolo: "App rilasciata",
        descrizione: "Versione 0.1.0",
    },
];
