import React, { useState, useEffect } from "react";
import { Route, Routes, Outlet, useLocation } from "react-router";
import IsDark from "./IsDark";
import { motion, AnimatePresence } from "framer-motion";
import { Toaster } from "react-hot-toast";
import API from "./API";
import LoadingPage from "./components/LoadingPage";
import pkg from "../package.json"; //la versione dell'applicazione, seguirà un changelog
//componenti
import Header from "./components/Header";
//pagine
import Home from "./pagine/Home";
import Users from "./pagine/Users";
import Settings from "./pagine/Settings";
import Login from "./pagine/Login";
import Ordini from "./pagine/Ordini";
import Cerca from "./pagine/Cerca";
import Carrello from "./pagine/Carrello";
import AdminArea from "./pagine/AdminArea";
import Notizia from "./components/notizie/Notizia";
//css
import "./App.css";

const pageVariants = {
    initial: {
        opacity: 0,
    },
    enter: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
    },
};

/* 
backup

const pageVariants = {
    initial: {
        opacity: 0,
        y: "-100%",
    },
    enter: {
        opacity: 1,
        y: "0%",
        transition: {
            duration: 0.5,
            ease: "easeInOut",
        },
    },
    exit: {
        opacity: 0,
        y: "100%",
        transition: {
            duration: 0.5,
            ease: "easeInOut",
        },
    },
};
*/

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);
    const [userHash, setUserHash] = useState("");

    const location = useLocation();

    useEffect(() => {
        autoDark();
        trylogin();

        const matchMedia = window.matchMedia("(prefers-color-scheme: dark)");

        matchMedia.addEventListener("change", autoDark);

        return () => matchMedia.removeEventListener("change", autoDark);
    }, []);

    //gestione del login
    const { version } = pkg;
    window.version = version;

    const trylogin = () => {
        if (localStorage.getItem("cliente") !== null) {
            const dati = JSON.parse(localStorage.getItem("cliente")).dati;
            //procedo con il login
            API.hashLog(dati)
                .then((data) => {
                    if (data.status) {
                        setIsLoggedIn(true);
                        setUserHash(
                            JSON.parse(localStorage.getItem("cliente")).dati
                                .hash
                        );
                    } else setIsLoggedIn(false);
                    setLoading(false);
                })
                .catch((err) => {
                    setIsLoggedIn(false);
                    setLoading(false);
                });
        } else setLoading(false);
    };

    const autoDark = () => {
        if (IsDark()) {
            document
                .getElementsByTagName("body")[0]
                .setAttribute("data-bs-theme", "dark");
            document
                .querySelector('meta[name="theme-color"]')
                .setAttribute("content", "#212529");
        } else {
            document
                .getElementsByTagName("body")[0]
                .setAttribute("data-bs-theme", "auto");
            document
                .querySelector('meta[name="theme-color"]')
                .setAttribute("content", "#2d3047");
        }
    };

    if (!isLoggedIn && !loading)
        return <Login logged={() => setIsLoggedIn(true)} />;
    else if (!isLoggedIn && loading) return <LoadingPage />;
    else
        return (
            <div className="App">
                <Header />
                <Toaster position="top-left" reverseOrder={false} />

                <AnimatePresence mode="wait">
                    <Routes location={location}>
                        <Route
                            path="/"
                            element={
                                <motion.div
                                    key="home"
                                    initial="initial"
                                    animate="enter"
                                    exit="exit"
                                    variants={pageVariants}
                                >
                                    <Home />
                                </motion.div>
                            }
                        />
                        <Route
                            path="/users"
                            element={
                                <motion.div
                                    key="users"
                                    initial="initial"
                                    animate="enter"
                                    exit="exit"
                                    variants={pageVariants}
                                >
                                    <Users />
                                </motion.div>
                            }
                        />
                        <Route
                            path="/settings"
                            element={
                                <motion.div
                                    key="settings"
                                    initial="initial"
                                    animate="enter"
                                    exit="exit"
                                    variants={pageVariants}
                                >
                                    <Settings />
                                </motion.div>
                            }
                        />
                        <Route
                            path="/ordini"
                            element={
                                <motion.div
                                    key="ordini"
                                    initial="initial"
                                    animate="enter"
                                    exit="exit"
                                    variants={pageVariants}
                                >
                                    <Ordini />
                                </motion.div>
                            }
                        />
                        <Route
                            path="/cerca"
                            element={
                                <motion.div
                                    key="cerca"
                                    initial="initial"
                                    animate="enter"
                                    exit="exit"
                                    variants={pageVariants}
                                >
                                    <Cerca />
                                </motion.div>
                            }
                        />
                        <Route
                            path="/carrello"
                            element={
                                <motion.div
                                    key="carrello"
                                    initial="initial"
                                    animate="enter"
                                    exit="exit"
                                    variants={pageVariants}
                                >
                                    <Carrello />
                                </motion.div>
                            }
                        />
                        <Route
                            path="/news/:id"
                            element={
                                <motion.div
                                    key="notizia"
                                    initial="initial"
                                    animate="enter"
                                    exit="exit"
                                    variants={pageVariants}
                                >
                                    <Notizia />
                                </motion.div>
                            }
                        />
                        {userHash ===
                            "$2y$10$EW.nf22Tikk2uel6hUhXUOI42.dLpcWCQgMvLHdudPaDXx.mdQFgC" && (
                            <Route
                                path="/admin"
                                element={
                                    <motion.div
                                        key="admin"
                                        initial="initial"
                                        animate="enter"
                                        exit="exit"
                                        variants={pageVariants}
                                    >
                                        <AdminArea />
                                    </motion.div>
                                }
                            />
                        )}
                    </Routes>
                </AnimatePresence>

                <Outlet />

                {/* spacer */}
                <div style={{ height: "70px" }}></div>
            </div>
        );
}

export default App;
