import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import IsDark from "../IsDark";
import API from "../API";
import Alerter from "../components/carrello/Alerter";
import IndirizzoCard from "../components/users/IndirizzoCard";
import RiepilogoTable from "../components/carrello/RiepilogoTable";
import Button from "../components/inputs/Button";
import FloatingTextArea from "../components/inputs/FloatingTextArea";
import Carica from "../components/carrello/Carica";
import { toast } from "react-hot-toast";

const Carrello = () => {
    const [accNavigate, setAccNavigate] = useState(0);
    const [errors, setErrors] = useState(false);
    const [indirizzi, setIndirizzi] = useState([]);
    //parte alerter
    const [vediAler, setVediAlert] = useState(false);
    //dati usati poi
    const [dati, setDati] = useState({
        spedizione: undefined,
        fatturazione: undefined,
        note: undefined,
    });
    //al cambio con true invia l'ordine
    const [vediCarica, setVediCarica] = useState(false);

    useEffect(() => {
        getIndirizzi();
    }, []);

    //se ci sono errori, mostro l'alert per poter riprovare.
    useEffect(() => {
        if (errors !== false) setVediAlert(true);
    }, [errors]);

    function getIndirizzi() {
        const userId = JSON.parse(localStorage.getItem("cliente")).dati.id;
        API.get("indirizzi", userId)
            .then((result) => {
                setIndirizzi(
                    Array.isArray(result.data) ? result.data : [result.data]
                );
            })
            .catch((err) => {
                setErrors("Indirizzi: " + err);
            });
    }

    return (
        <section
            className={`container ${
                IsDark() ? "bg-dark" : "bg-white"
            } p-2 shadow rounded`}
        >
            <Carica
                vedi={vediCarica}
                chiudi={() => setVediCarica(false)}
                dati={dati}
            />
            <Alerter
                vedi={vediAler}
                chiudi={() => setVediAlert(false)}
                errore={errors}
            />
            {/* defaultActiveKey gestisce i click, activeKey va a stato */}
            <Accordion activeKey={accNavigate}>
                <Accordion.Item
                    eventKey={0}
                    onClick={() => accNavigate > 0 && setAccNavigate(0)}
                >
                    <Accordion.Header>Spedizione</Accordion.Header>
                    <Accordion.Body>
                        <div className="d-flex flex-wrap gap-4 justify-content-center justify-content-md-start">
                            {indirizzi.map((ind, i) => (
                                <IndirizzoCard
                                    dati={ind}
                                    key={i}
                                    className={`${
                                        dati.spedizione === i &&
                                        "border border-2 border-primary"
                                    }`}
                                    onClick={() => {
                                        setDati((prev) => {
                                            return { ...prev, spedizione: ind.id };
                                        });
                                        setAccNavigate(1);
                                    }}
                                />
                            ))}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item
                    eventKey={1}
                    onClick={() => accNavigate > 1 && setAccNavigate(1)}
                >
                    <Accordion.Header>Fatturazione</Accordion.Header>
                    <Accordion.Body>
                        <div className="d-flex flex-wrap gap-4 justify-content-center justify-content-md-start">
                            {indirizzi.map((ind, i) => (
                                <IndirizzoCard
                                    dati={ind}
                                    key={i}
                                    className={`${
                                        dati.fatturazione === i &&
                                        "border border-2 border-primary"
                                    }`}
                                    onClick={() => {
                                        setDati((prev) => {
                                            return { ...prev, fatturazione: ind.id };
                                        });
                                        setAccNavigate(2);
                                    }}
                                />
                            ))}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={2}>
                    <Accordion.Header>Riepilogo e conferma</Accordion.Header>
                    <Accordion.Body>
                        <RiepilogoTable setError={(val) => setErrors(val)} />
                        <div>
                            <FloatingTextArea
                                label="Note"
                                className="shadow"
                                onChange={(v) =>
                                    setDati((prev) => {
                                        return {
                                            ...prev,
                                            note: v,
                                        };
                                    })
                                }
                            />
                        </div>
                        <div className="d-flex justify-content-center">
                            <Button
                                className="flex-md-grow-0 flex-grow-1"
                                onClick={() => {
                                    if (navigator.onLine) setVediCarica(true);
                                    else
                                        toast.error(
                                            "Devi essere online per procedere"
                                        );
                                }}
                            >
                                Conferma ordine
                            </Button>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </section>
    );
};

export default Carrello;
