import React, { useState, useEffect } from "react";
import FloatingInput from "../inputs/FloatingInput";
import { motion } from "framer-motion";
import IsDark from "../../IsDark";
import API from "../../API";
import { toast } from "react-hot-toast";
import Table from "../Table";
import Container from "../Container";
import ModalProdotto from "./ModalProdotto";

const TopHomePart = () => {
    const [cercArray, setCercArray] = useState([]);
    //parte del modal
    const [vediM, setVediM] = useState(false);
    const [clicked, setClicked] = useState(false);

    const cerca = (val) => {
        if (val.length > 0) {
            API.mag("prodotti", val)
                .then((result) => {
                    console.log(result);
                    if (Array.isArray(result.data)) {
                        setCercArray(result.data);
                    } else {
                        setCercArray([result.data]);
                        setClicked(result.data);
                        setVediM(true);
                    }
                })
                .catch((err) => {
                    setClicked({
                        codice: val,
                        Da_revisionare: 0,
                        In_lavorazione: 0,
                        Nuovo: 0,
                        Revisionato: 0,
                        Revisionato_Bosch: 0,
                    });
                    setVediM(true);
                });
        }
    };

    return (
        <React.Fragment>
            <ModalProdotto
                vedi={vediM}
                chiudi={() => setVediM(false)}
                dati={clicked}
            />
            <Container>
                <FloatingInput label="Cerca" onEnter={cerca} />
                <div className="overflow-auto" style={{ maxHeight: "400px" }}>
                    {cercArray.length > 0 && (
                        <Table striped={false} className="table-hover">
                            <Table.Thead>
                                <tr>
                                    <th className="text-start">Codice</th>
                                    <th>Pronte</th>
                                    <th>Da fare</th>
                                </tr>
                            </Table.Thead>
                            <Table.Tbody>
                                {cercArray.map((val, i) => (
                                    <tr
                                        key={i}
                                        className="cursor-pointer"
                                        onClick={() => {
                                            setClicked(val)
                                            setVediM(true)
                                        }}
                                    >
                                        <td className="text-start">
                                            {val.codice}
                                        </td>
                                        <td>
                                            {val.Revisionato +
                                                val.Nuovo +
                                                val.Revisionato_Bosch}
                                        </td>
                                        <td>
                                            {val.Da_revisionare +
                                                val.In_lavorazione}
                                        </td>
                                    </tr>
                                ))}
                            </Table.Tbody>
                        </Table>
                    )}
                </div>
            </Container>
        </React.Fragment>
    );
};

export default TopHomePart;
