import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import API from "../../API";
import FloatingInput from "../inputs/FloatingInput";
import { toast } from "react-hot-toast"

const ModalAggIndirizzo = ({ vedi, chiudi, dati, update }) => {
    const inputs = useRef();

    const sendData = () => {
        //switch fra put e post
        const send = {};

        inputs.current.querySelectorAll("input").forEach((val) => {
            send[val.name] = val.value;
        })
        const user = JSON.parse(localStorage.getItem("cliente")).dati;
        send.iduser = user.id;
        
        if (dati.id) {
            API.put("indirizzi", dati.id, send).then(() => {
                update()
                chiudi()
            }).catch(() => {
                toast.error("Non sono riuscito ad aggiornare i dati")
            })
        } else {
            API.post("indirizzi", send).then(() => {
                update()
                chiudi()
            }).catch(() => {
                toast.error("Non sono riuscito ad aggiungere l'indirizzo")
            })
        }
    }

    return (
        <Modal
            size="md"
            fullscreen="md-down"
            onHide={chiudi}
            show={vedi}
            centered
            scrollable
        >
            <Modal.Header closeButton>
                <Modal.Title>{(dati.nome_indirizzo) ? dati.nome_indirizzo : "Aggiungi indirizzo"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div ref={inputs}>
                    <FloatingInput
                        name="nome_indirizzo"
                        label="Nome di questo indirizzo"
                        id="nome_indirizzo"
                        defaultValue={dati.nome_indirizzo ? dati.nome_indirizzo : "Indirizzo di spedizione"}
                    />
                    <FloatingInput
                        name="azienda"
                        label="Azienda"
                        id="azienda"
                        defaultValue={dati.azienda && dati.azienda}
                    />
                    <FloatingInput
                        name="indirizzo"
                        label="Indirizzo"
                        id="indirizzo"
                        defaultValue={dati.indirizzo && dati.indirizzo}
                    />
                    <FloatingInput
                        name="cap"
                        label="Cap"
                        id="cap"
                        type="number"
                        defaultValue={dati.cap && dati.cap}
                    />
                    <FloatingInput
                        name="citta"
                        label="Città"
                        id="citta"
                        defaultValue={dati.citta && dati.citta}
                    />
                    <FloatingInput
                        name="provincia"
                        label="Provincia"
                        id="provincia"
                        defaultValue={dati.provincia && dati.provincia}
                    />
                    <FloatingInput
                        name="partita_iva"
                        label="Partita Iva"
                        id="partita_iva"
                        defaultValue={dati.partita_iva && dati.partita_iva}
                    />
                    <FloatingInput
                        name="codice_fiscale"
                        label="Codice Fiscale"
                        id="codice_fiscale"
                        defaultValue={dati.codice_fiscale && dati.codice_fiscale}
                    />
                    <FloatingInput
                        name="pec"
                        label="Indirizzo PEC"
                        id="pec"
                        type="email"
                        defaultValue={dati.pec && dati.pec}
                    />
                    <FloatingInput
                        name="cod_univoco"
                        label="Codice univoco"
                        id="cod_univoco"
                        defaultValue={dati.cod_univoco && dati.cod_univoco}
                    />
                    <FloatingInput
                        name="telefono"
                        label="Telefono"
                        type="number"
                        id="telefono"
                        defaultValue={dati.telefono && dati.telefono}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-evenly">
                <div
                    className="h-100 flex-fill text-center cursor-pointer"
                    onClick={chiudi}
                >
                    <p className="mb-0 text-indian">chiudi</p>
                </div>
                <div
                    className="h-100 flex-fill text-center cursor-pointer"
                    onClick={sendData}
                >
                    <p className="mb-0 text-primary">conferma</p>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalAggIndirizzo;

ModalAggIndirizzo.defaultProps = {
    vedi: false,
    dati: {}
}