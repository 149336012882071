import React, { useState } from "react";
import IsDark from "../../IsDark";
import { motion } from "framer-motion";
import DettaglioOrdine from "./DettaglioOrdine";

const TabellaOrdini = ({ dati }) => {
    const [vediM, setVediM] = useState(false);
    const [datiM, setDatiM] = useState({});

    return (
        <div className="mt-4 container d-flex justify-content-evenly flex-wrap gap-3">
            <DettaglioOrdine
                vedi={vediM}
                dati={datiM}
                chiudi={() => setVediM(false)}
            />
            {dati.map((v, i) => (
                <Vocina
                    dati={v}
                    key={i}
                    onClick={() => {
                        setDatiM(v);
                        setVediM(true);
                    }}
                />
            ))}
        </div>
    );
};

export default TabellaOrdini;

const Vocina = ({ dati, onClick }) => {
    let voce = dati.data.split(" ");
    return (
        <motion.div
            className={`${
                IsDark() ? "bg-dark" : "bg-white"
            } p-3 shadow rounded cursor-pointer`}
            whileHover={{
                scale: 1.05,
            }}
            whileTap={{
                scale: 0.95,
            }}
            onClick={onClick}
        >
            <div className="d-flex justify-content-between gap-5">
                <div>
                    <h5>
                        {new Date(voce[0]).toLocaleDateString("it-IT", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                        })}
                    </h5>
                </div>
                <div>
                    <h5>{voce[1]}</h5>
                </div>
            </div>
            <div className="d-flex justify-content-between align-middle align-items-center">
                <div>
                    <p className="mb-0">
                        Tot.Prodotti:{" "}
                        <span className="text-primary font-weight-bold">
                            {dati.qtaProdotti}
                        </span>
                    </p>
                </div>
                <div className="bg-indian text-white p-2">
                    {parseFloat(dati.totale_ordine).toFixed(2)} €
                </div>
            </div>
        </motion.div>
    );
};
