import React from "react";
import { useParams } from "react-router-dom";
import Container from "../Container";

const Notizia = () => {
    //estrazione id
    const { id } = useParams();
    //caricamento dinamico articolo
    let Art;
    try {
        Art = require(`./article/Articolo${id}`).default;
        
    } catch (error) {
        Art = require("./ArticoloFail").default;
    }

    return (
        <Container className="text-start">
            <Art />
        </Container>
    );
};

export default Notizia;
