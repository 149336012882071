import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import API from "../../API";
import nf from "../../asset/404.svg";
import Table from "../Table";
import PlaceholderTables from "../PlaceholderTables";
import CrossTables from "./CrossTables";
import PriceTable from "./PriceTable";
import Button from "../inputs/Button";
import AdderModal from "./AdderModal";
import RepairerModal from "./RepairerModal";

const ModalProdotto = ({ vedi, chiudi, dati }) => {
    const [lizy, setLizy] = useState({
        immagine: nf,
        codici: [],
    });
    const [crosses, setCrosses] = useState([]);
    const [prezzi, setPrezzi] = useState({});
    const [loadedCross, setLoadedCross] = useState(false); //array con cross e qta
    const [loadedLizy, setLoadedLizy] = useState(false); //lizy con le crosses e immagine
    const [loadedPrice, setLoadedPrice] = useState(false); //se ha caricato il prezzo
    //modal acquista
    const [vistaM, setVistaM] = useState(false);
    const [scontiExp, setScontiExp] = useState({
        revisionato: 0,
        revisionato_bosch: 0,
        nuovo: 0,
    });
    const [carcasseExp, setCarcasseExp] = useState({
        revisionato: false,
        revisionato_bosch: false,
        nuovo: false,
    });
    //modal riparazione
    const [vistaR, setVistaR] = useState(false);

    useEffect(() => {
        if (vedi) {
            getLizy();
            getPrice();
        } else {
            setLizy([]);
            setCrosses([]);
            setPrezzi({});
            setLoadedCross(false);
            setLoadedLizy(false);
            setLoadedPrice(false);
            setVistaM(false);
        }
    }, [vedi]);

    useEffect(() => {
        if (loadedLizy) {
            getQta();
        }
    }, [loadedLizy]);

    function getPrice() {
        setLoadedPrice(false);
        API.price(dati.codice)
            .then((result) => {
                setPrezzi(result.data);
                setLoadedPrice(true);
            })
            .catch(() => {
                setPrezzi({});
                setLoadedPrice(true);
            });
    }

    async function getQta() {
        setLoadedCross(false);
        let temp = [];
        for (const codice of lizy.codici) {
            await API.mag("prodotti", codice)
                .then((result) => {
                    temp.push(result.data);
                })
                .catch(() => {
                    temp.push({
                        codice: codice,
                        Da_revisionare: 0,
                        In_lavorazione: 0,
                        Revisionato: 0,
                        Nuovo: 0,
                        Revisionato_Bosch: 0,
                    });
                });
        }
        setCrosses(temp);
        setLoadedCross(true);
    }

    function getLizy() {
        setLoadedLizy(false);
        API.lizy(dati.codice)
            .then((result) => {
                let codici = [];
                Object.keys(result.data)
                    .filter((key) => key.indexOf("codice") > -1)
                    .forEach((key) => {
                        if (
                            result.data[key] !== null &&
                            result.data[key] !== dati.codice
                        )
                            codici.push(result.data[key]);
                    });
                setLizy({
                    immagine: result.data.immagine,
                    codici,
                });
                setLoadedLizy(true);
            })
            .catch(() => {});
    }

    return (
        <>
            <Modal
                centered
                size="lg"
                fullscreen="md-down"
                onHide={chiudi}
                show={vedi}
                scrollable={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{dati.codice}</Modal.Title>
                </Modal.Header>
                <Modal.Body
                    className={`${(vistaM || vistaR) && "overflow-hidden"}`}
                >
                    <div className="d-flex justify-content-evenly mb-3 gap-3 flex-wrap align-items-center">
                        <div className="flex-grow-1 flex-md-grow-0 text-center">
                            <img
                                src={lizy.immagine}
                                width="350px"
                                className="shadow rounded"
                            />
                        </div>
                        <div className="flex-grow-1 align-items-center">
                            <h4>Disponibilità</h4>
                            <Table striped={false}>
                                <Table.Tbody>
                                    <tr>
                                        <td>Da revisionare</td>
                                        <td>{dati?.Da_revisionare}</td>
                                    </tr>
                                    <tr>
                                        <td>In lavorazione</td>
                                        <td>{dati?.In_lavorazione}</td>
                                    </tr>
                                    <tr>
                                        <td>Nuovo</td>
                                        <td>{dati?.Nuovo}</td>
                                    </tr>
                                    <tr>
                                        <td>Revisionato</td>
                                        <td>{dati?.Revisionato}</td>
                                    </tr>
                                    <tr>
                                        <td>Revisionato Bosch</td>
                                        <td>{dati?.Revisionato_Bosch}</td>
                                    </tr>
                                </Table.Tbody>
                            </Table>
                        </div>
                    </div>
                    <div>
                        <PriceTable
                            dati={prezzi}
                            loaded={loadedPrice}
                            exportSconti={setScontiExp}
                            exportCarcasse={(setCarcasseExp)}
                        />
                    </div>
                    <div>
                        <h5>Crosses</h5>
                        {loadedCross ? (
                            <CrossTables dati={crosses} />
                        ) : (
                            <PlaceholderTables col={3} row={2} />
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer className="position-relative ws">
                    <div className="w-100 d-flex gap-4">
                        <Button
                            className="w-100"
                            onClick={() => setVistaR(true)}
                        >
                            Ric.Riparazione
                        </Button>
                        <Button
                            className="w-100"
                            onClick={() => setVistaM(true)}
                            disabled={!loadedPrice ? true : false}
                        >
                            Aggiungi al carrello
                        </Button>
                    </div>
                    <AdderModal
                        vedi={vistaM}
                        chiudi={() => setVistaM(false)}
                        prezzi={scontiExp}
                        qta={dati}
                        carcasse={carcasseExp}
                    />
                    <RepairerModal
                        vedi={vistaR}
                        chiudi={() => setVistaR(false)}
                        codice={dati?.codice}
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalProdotto;

ModalProdotto.defaultProps = {
    dati: { codice: "" },
    vedi: false,
};
