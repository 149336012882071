import React, { useState, useRef } from "react";
import { toast } from "react-hot-toast";
import FloatingInput from "../inputs/FloatingInput";
import { motion } from "framer-motion";
import Button from "../inputs/Button";
import API from "../../API";

const Inputs = ({ mode, logged }) => {
    const [ pwd, setPwd ] = useState("")
    const [ inp, setInp ] = useState({
        email: null,
        password: null,
        conferma_password: null
    })

    const inputs = useRef()

    //con transition: {duration: x} regolo la durata
    const variants = {
        active: {
            opacity: 1,
            scale: 1,
            display: "block"
        },
        hidden: {
            opacity: 0,
            scale: 0,
            transitionEnd: { display: "none" }
        }
    }

    const handleInput = (nome, val) => {
        if (nome === "email") {
            if (val.length >= 6 && val.indexOf("@") > -1)
                setInp({ ...inp, email: null })
            else
                setInp({ ...inp, email: false })
        } else if (nome === "password") {
            setPwd(val)
            setInp({ ...inp, conferma_password: false })
            if (val.length >= 8)
                setInp({ ...inp, password: null })
            else
                setInp({ ...inp, password: false })
        } else if (nome === "conferma_password") {
            if (pwd === val)
                setInp({ ...inp, conferma_password: null })
            else
                setInp({ ...inp, conferma_password: false })
        }
    }

    //invio i dati al server
    const sendData = () => {
        const send = {}

        inputs.current.querySelectorAll("input").forEach((val) => {
            send[val.name] = val.value
        })

        send.mode = mode;

        if (send.email.length < 6 || send.email.indexOf("@") < 0) {
            toast.error("L'email non è valida")
            return false;
        }
        if (send.password.length < 8) {
            toast.error("La password non è valida")
            return false;
        }
        if (mode === "registra") {
            if (send.conferma_password !== send.password) {
                toast.error("Le password non coincidono")
                return false;
            }
        }

        API.login(send).then((data) => {
            console.log("loggato, risposta: ", data)
            localStorage.setItem("cliente", JSON.stringify({
                dati: data.dati,
                data: new Date().toISOString().substring(0, 10)
            }))
            localStorage.removeItem("carrello")
            logged()
        }).catch(err => console.log(err))
    }

    return (
        <div ref={inputs}>
            <FloatingInput
                name="email"
                type="email"
                placeholder="Indirizzo Email"
                label="Indirizzo Email"
                id="email"
                valid={inp.email}
                invalidText="Inserisci un email valida"
                onChange={handleInput.bind(this, "email")}
            />
            <FloatingInput
                name="password"
                type="password"
                placeholder="Password"
                label="Password"
                id="password"
                onChange={handleInput.bind(this, "password")}
                valid={inp.password}
                invalidText="La password deve avere almeno 8 caratteri"
            />
            <motion.div
                variants={variants}
                initial="hidden"
                animate={(mode === "login") ? "hidden" : "active"}
            >
                <FloatingInput
                    name="conferma_password"
                    type="password"
                    placeholder="Conferma Password"
                    id="conferma_password"
                    onChange={handleInput.bind(this, "conferma_password")}
                    label="Conferma Password"
                    valid={inp.conferma_password}
                    invalidText="Le password non coincidono"
                />
            </motion.div>

            <div className="mt-4 text-center">
                <Button onClick={sendData}>
                    {mode === "login" ? "Accedi" : "Registrati"}
                </Button>
            </div>
        </div>
    );
};

export default Inputs;
