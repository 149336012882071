import React, { useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const variants = {
    hidden: {
        x: "500%",
    },
    show: {
        x: "0%",
    },
};

const NotiziaBlock = ({ id, titolo, descrizione }) => {
    const [hovered, setHovered] = useState(false);

    const navigate = useNavigate();

    return (
        <motion.div
            className="p-3 shadow mb-3 text-start rounded d-flex overflow-hidden align-items-center align-middle bg-primary text-white"
            whileHover={{
                scale: 1.01,
            }}
            whileTap={{
                scale: 0.99,
            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onTap={() => navigate(`/news/${id}`)}
        >
            <div className="flex-fill">
                <h6>{titolo}</h6>
                <p className="mb-0">{descrizione}</p>
            </div>
            <motion.span
                className="material-symbols-outlined"
                variants={variants}
                initial="hidden"
                animate={hovered ? "show" : "hidden"}
                transition={{
                    duration: 0.4,
                    type: "spring",
                }}
            >
                arrow_forward_ios
            </motion.span>
        </motion.div>
    );
};

export default NotiziaBlock;
