import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import API from "../../API";
import { toast } from "react-hot-toast";
import Table from "../Table";
import nf from "../../asset/404.svg";
import Button from "../inputs/Button";
import AcquistaModal from "./AcquistaModal";
import PrezziHandler from "./PrezziHandler";

const ModalProdotto = ({ vedi, chiudi, dati, newSearch, sconto }) => {
    const [lizy, setLizy] = useState({
        alternativi: [],
        revisionati: [],
    }); //i dati ti lizy
    const [cross, setCross] = useState([]); //la lista delle cross
    const [disp, IsDisp] = useState(false); //se prodotto disponibile attiva il bottone acquista
    //parte acquista modal
    const [qtaDisp, setQtaDisp] = useState({
        rev: 0,
        nuovo: 0,
        revB: 0,
    });
    const [vediAM, setVediAM] = useState(false);
    //il prezzo del prodotto
    const [prezzo, setPrezzo] = useState({
        revisionato: {},
        nuovo: {},
        revisionato_bosch: {},
    });
    const [priceLoaded, setPriceLoaded] = useState(false);

    //questo abiliterà il bottone compra
    useEffect(() => {
        if (vedi) {
            let disponibile = false,
                qtaTot = { rev: 0, nuovo: 0, revB: 0 };
            [
                "Da_revisionare",
                "In_lavorazione",
                "Revisionato",
                "Revisionato_Bosch",
                "Nuovo",
            ].forEach((key) => {
                if (typeof dati[key] !== "undefined" && dati[key] > 0) {
                    disponibile = true;
                    if (key === "Nuovo") {
                        qtaTot.nuovo += dati.Nuovo;
                    } else if (key === "Revisionato_Bosch") {
                        qtaTot.revB += dati.revB;
                    } else {
                        qtaTot.rev += dati[key];
                    }
                }
            });

            if (cross.length > 0) {
                cross.forEach((val) => {
                    if (val.da_fare !== false) {
                        qtaTot.rev += val.da_fare;
                    }
                    if (val.fatte !== false) {
                        qtaTot.nuovo += val.fatte;
                    }
                });
            }

            IsDisp(disponibile);
            setQtaDisp(qtaTot);
        }
    }, [dati, cross, vedi]);

    //avvia l'ottenimento di lizy
    useEffect(() => {
        if (!vedi) {
            setCross([]);
            setLizy({
                alternativi: [],
                revisionati: [],
            });
        } else {
            if (typeof dati.codice !== "undefined") {
                getLizy();
                getPrice();
            }
        }
    }, [vedi]);

    //ottiene il prezzo del prodotto
    function getPrice() {
        //da fare parte delle api che ottiene il prezzo
        API.price(dati.codice)
            .then((result) => {
                let prezzi = result.data;
                let temp = {
                    revisionato: prezzi.revisionato || {},
                    revisionato_bosch: prezzi.revisionato_bosch || {},
                    nuovo: prezzi.nuovo || {},
                };
                setPrezzo(temp);
                setPriceLoaded(true);
            })
            .catch((err) => {
                setPriceLoaded({
                    revisionato: 0,
                    revisionato_bosch: 0,
                    nuovo: 0,
                });
                setPriceLoaded(true);
            });
    }

    const getQtyCross = async (cross) => {
        const tempCross = [];
        for (const cod of cross) {
            let tempCod = cod.codice,
                tempDaFare,
                tempFatte;
            await API.mag("prodotti", cod.codice)
                .then((result) => {
                    if (result.status) {
                        let temp;
                        if (Array.isArray(result.data)) temp = result.data[0];
                        else temp = result.data;

                        tempFatte =
                            parseInt(temp.Nuovo !== null ? temp.Nuovo : 0) +
                            parseInt(
                                temp.Revisionato !== null ? temp.Revisionato : 0
                            ) +
                            parseInt(
                                temp.Revisionato_Bosch !== null
                                    ? temp.Revisionato_Bosch
                                    : 0
                            );
                        tempDaFare =
                            parseInt(
                                temp.Da_revisionare !== null
                                    ? temp.Da_revisionare
                                    : 0
                            ) +
                            parseInt(
                                temp.In_Lavorazione ? temp.In_Lavorazione : 0
                            );
                    } else {
                        tempDaFare = false;
                        tempFatte = false;
                    }
                })
                .catch((err) => {
                    tempDaFare = false;
                    tempFatte = false;
                });
            tempCross.push({
                codice: tempCod,
                da_fare: tempDaFare,
                fatte: tempFatte,
            });
        }
        setCross(tempCross);
    };

    //ottiene i dati di lizy
    function getLizy() {
        API.lizy(dati.codice)
            .then((result) => {
                if (result.status) {
                    let vals;
                    let crossvals = [];
                    if (Array.isArray(result.data)) {
                        vals = result.data[0];
                    } else {
                        vals = result.data;
                    }
                    const val = {
                        codice: vals.codice,
                        revisionati: [],
                        alternativi: [],
                        immagine: vals.immagine,
                        sigla_tipo: vals.sigla_tipo,
                        tipo: vals.tipo,
                        produttore: vals.produttore,
                    };
                    for (let i = 0; i < 8; i++) {
                        let tempAlt, tempRev;
                        if (i < 1) {
                            tempAlt = vals.codice_alternativo;
                            tempRev = vals.codice_revisionato;
                        } else {
                            tempAlt = vals[`codice_alternativo${i}`];
                            tempRev = vals[`codice_revisionato${i}`];
                        }

                        if (
                            tempAlt !== null &&
                            typeof tempAlt !== "undefined"
                        ) {
                            val.alternativi.push(tempAlt);
                            crossvals.push({
                                codice: tempAlt,
                                pronte: "placeholder",
                                da_fare: "placeholder",
                            });
                        }
                        if (
                            tempRev !== null &&
                            typeof tempRev !== "undefined"
                        ) {
                            val.revisionati.push(tempRev);
                            crossvals.push({
                                codice: tempRev,
                                pronte: "placeholder",
                                da_fare: "placeholder",
                            });
                        }
                    }

                    setLizy(val);
                    //setCross(crossvals);
                    getQtyCross(crossvals);
                }
            })
            .catch((err) => {
                toast.error(err);
            });
    }

    return (
        <React.Fragment>
            <AcquistaModal
                vedi={vediAM}
                qta={qtaDisp}
                chiudi={() => setVediAM(false)}
                codice={dati.codice}
                prezzi={prezzo}
                sconto={sconto}
            />

            <Modal
                size="lg"
                fullscreen="lg-down"
                show={vedi}
                onHide={chiudi}
                centered
                scrollable
            >
                <Modal.Header closeButton>
                    <Modal.Title>{dati.codice}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-evenly gap-2 flex-wrap mb-4">
                        <div className="w-50">
                            <img
                                src={`${
                                    lizy.immagine
                                        ? lizy.immagine +
                                          `?last_update=${new Date().toISOString()}`
                                        : nf
                                }`}
                                className="shadow rounded w-100"
                            />
                        </div>
                        <div>
                            <h5>Disponibili in magazzino</h5>
                            <Table striped={false}>
                                <Table.Tbody>
                                    <tr>
                                        <td className="text-start">
                                            Da revisionare
                                        </td>
                                        <td className="text-end">
                                            {dati.Da_revisionare}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-start">
                                            In lavorazione
                                        </td>
                                        <td className="text-end">
                                            {dati.In_Lavorazione}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-start">
                                            Revisionato
                                        </td>
                                        <td className="text-end">
                                            {dati.Revisionato}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-start">
                                            Revisionato Bosch
                                        </td>
                                        <td className="text-end">
                                            {dati.Revisionato_Bosch}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-start">Nuovo</td>
                                        <td className="text-end">
                                            {dati.Nuovo}
                                        </td>
                                    </tr>
                                </Table.Tbody>
                            </Table>
                            <h6>Prezzi</h6>
                            <PrezziHandler
                                sconto={sconto}
                                prezzo={prezzo}
                                priceLoaded={priceLoaded}
                            />
                        </div>
                    </div>
                    {/* alternativi */}
                    <div>
                        <h5>Alternativi</h5>
                        <Table striped={false}>
                            <Table.Thead>
                                <tr>
                                    <th>codice</th>
                                    <th className="text-center">pronte</th>
                                    <th className="text-center">da fare</th>
                                </tr>
                            </Table.Thead>
                            <Table.Tbody>
                                {cross.map((v, i) => (
                                    <tr
                                        key={i}
                                        onClick={() => newSearch(v.codice)}
                                    >
                                        <td className="cursor-pointer">
                                            {v.codice}
                                        </td>
                                        <td className="text-center">
                                            {v.fatte === false ? "0" : v.fatte}
                                        </td>
                                        <td className="text-center">
                                            {v.da_fare === false
                                                ? "0"
                                                : v.da_fare}
                                        </td>
                                    </tr>
                                ))}
                            </Table.Tbody>
                        </Table>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-evenly">
                    <Button variant="primary">Riparazione</Button>
                    {(disp && priceLoaded) && (
                        <Button
                            variant="primary"
                            onClick={() => setVediAM(true)}
                        >
                            Acquista
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};

export default ModalProdotto;
