import React, { useRef, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Cart from "../../Cart";
import toast from "react-hot-toast";
import Table from "../Table";

const AcquistaModal = ({ vedi, qta, chiudi, codice, prezzi, sconto }) => {
    const [scelti, setScelti] = useState({
        revisionato: 0,
        nuovo: 0,
        revisionato_bosch: 0,
    });
    const [prezziScontati, setPrezziScontati] = useState({
        revisionato: 0,
        nuovo: 0,
        revisionato_bosch: 0,
    });

    useEffect(() => {
        if (prezzi && sconto) {
            if (typeof prezzi.revisionato.prezzo !== "undefined") {
                setPrezziScontati((prev) => {
                    return {
                        ...prev,
                        revisionato: parseFloat(
                            prezzi.revisionato.prezzo -
                                (prezzi.revisionato.prezzo *
                                    sconto.revisionato) /
                                    100
                        ),
                    };
                });
            }
            if (typeof prezzi.revisionato_bosch.prezzo !== "undefined") {
                setPrezziScontati((prev) => {
                    return {
                        ...prev,
                        revisionato_bosch: parseFloat(
                            prezzi.revisionato_bosch.prezzo -
                                (prezzi.revisionato_bosch.prezzo *
                                    sconto.revisionato_bosch) /
                                    100
                        ),
                    };
                });
            }
            if (typeof prezzi.nuovo.prezzo !== "undefined") {
                setPrezziScontati((prev) => {
                    return {
                        ...prev,
                        nuovo: parseFloat(
                            prezzi.nuovo.prezzo -
                                (prezzi.nuovo.prezzo * sconto.nuovo) / 100
                        ),
                    };
                });
            }
        }
    }, [prezzi, sconto]);

    const inputs = useRef();

    function confermaAcquisto() {
        const richiesta = [];
        let valido = false;
        for (const tr of inputs.current.querySelectorAll("tr")) {
            const tds = tr.querySelectorAll("td");
            const inputCamp = tds[1].querySelector("input");
            if (inputCamp.value > 0 && inputCamp.disabled === false) {
                valido = true;
                richiesta.push({
                    nome: codice,
                    tipo: inputCamp.name,
                    qta: parseInt(inputCamp.value),
                    prezzo: parseFloat(tds[2].innerText.replaceAll(" €", "") / inputCamp.value),
                });
            }
        }
        if (valido) {
            try {
                Cart.aggiungi(richiesta);
                toast.success("Prodotto aggiunto");
                chiudi();
            } catch (error) {
                toast.error("Impossibile inserire il prodotto");
            }
        }
    }

    return (
        <Modal centered size="md" scrollable show={vedi} onHide={chiudi}>
            <Modal.Header closeButton>
                <Modal.Title>Acquista</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <section ref={inputs}>
                    <Table striped={false}>
                        <Table.Tbody>
                            <tr className="align-baseline">
                                <td>Revisionato</td>
                                <td className="text-center">
                                    <InputBtn
                                        max={qta.rev}
                                        name="revisionato"
                                        onChange={(value) => {
                                            setScelti((prev) => {
                                                return {
                                                    ...prev,
                                                    revisionato:
                                                        parseInt(value),
                                                };
                                            });
                                        }}
                                    />
                                </td>
                                <td className="text-end">
                                    {typeof prezziScontati.revisionato !==
                                        "undefined" &&
                                        parseFloat(
                                            prezziScontati.revisionato *
                                                scelti.revisionato
                                        ).toFixed(2) + " €"}
                                </td>
                            </tr>
                            <tr className="align-baseline">
                                <td>Revisionato Bosch</td>
                                <td className="text-center">
                                    <InputBtn
                                        max={qta.revB}
                                        name="revisionato_bosch"
                                        onChange={(value) => {
                                            setScelti((prev) => {
                                                return {
                                                    ...prev,
                                                    revisionato_bosch:
                                                        parseInt(value),
                                                };
                                            });
                                        }}
                                    />
                                </td>
                                <td className="text-end">
                                    {typeof prezziScontati.revisionato_bosch !==
                                        "undefined" &&
                                        parseFloat(
                                            prezziScontati.revisionato_bosch *
                                                scelti.revisionato_bosch
                                        ).toFixed(2) + " €"}
                                </td>
                            </tr>
                            <tr className="align-baseline">
                                <td>Nuovo</td>
                                <td className="text-center">
                                    <InputBtn
                                        max={qta.nuovo}
                                        name="nuovo"
                                        onChange={(value) => {
                                            setScelti((prev) => {
                                                return {
                                                    ...prev,
                                                    nuovo: parseInt(value),
                                                };
                                            });
                                        }}
                                    />
                                </td>
                                <td className="text-end">
                                    {typeof prezziScontati.nuovo !==
                                        "undefined" &&
                                        parseFloat(
                                            prezziScontati.nuovo * scelti.nuovo
                                        ).toFixed(2) + " €"}
                                </td>
                            </tr>
                        </Table.Tbody>
                    </Table>
                </section>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-evenly">
                <div className="cursor-pointer" onClick={chiudi}>
                    <p className="mb-0 text-danger">Annulla</p>
                </div>
                <div className="cursor-pointer" onClick={confermaAcquisto}>
                    <p className="mb-0 text-primary">Conferma</p>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default AcquistaModal;

const InputBtn = ({ max, name, onChange }) => {
    const [valore, setValore] = useState();

    const checkNumber = (val) => {
        let numero = val.target.value,
            correct;
        if (numero < max) correct = numero;
        else if (numero > max) correct = max;
        //parte dell'inferiore
        setValore(correct);
        onChange(correct);
    };

    return (
        <input
            type="number"
            name={name}
            value={valore}
            onChange={checkNumber}
            className="ps-2 pe-2 pt-1 pb-1"
            style={styles.input}
            placeholder="Qta"
            max={max}
            min={0}
            disabled={max > 0 ? false : true}
        />
    );
};

InputBtn.defaultProps = {
    max: 0,
};

const styles = {
    input: {
        maxWidth: "70px",
    },
};
