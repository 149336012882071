import React from "react";
import Table from "../Table";

const CrossTables = ({ dati }) => {
    return (
        <Table striped={false} className="table-hover">
            <Table.Thead>
                <tr>
                    <th className="text-start">Codice</th>
                    <th className="text-center">Pronte</th>
                    <th className="text-center">Da fare</th>
                </tr>
            </Table.Thead>
            <Table.Tbody>
                {dati.map((val, i) => (
                    <tr key={i} className="cursor-pointer">
                        <td className="text-start">{val.codice}</td>
                        <td className="text-center">
                            {val.Nuovo +
                                val.Revisionato +
                                val.Revisionato_Bosch}
                        </td>
                        <td className="text-center">{val.Da_revisionare + val.In_lavorazione}</td>
                    </tr>
                ))}
            </Table.Tbody>
        </Table>
    );
};

export default CrossTables;
