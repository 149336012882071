import React, { useEffect, useState } from "react";
import API from "../../API";
import Table from "../Table";
import ModalProdotto from "./ModalProdotto";
import IsDark from "../../IsDark";

const Lista = ({ cerca, newCerca }) => {
    const [vedi, setVedi] = useState([]);
    const [vediM, setVediM] = useState(false);
    const [codiceM, setCodiceM] = useState({});
    const [sconto, setSconto] = useState(0);

    useEffect(() => {
        getSconto();
    }, []);
    
    useEffect(() => {
        if (cerca.length > 0) avviaCerca(cerca);
    }, [cerca]);

    const avviaCerca = (prodotto) => {
        API.mag("prodotti", prodotto)
            .then((data) => {
                if (data.status) {
                    const result = data.data;
                    if (Array.isArray(result)) {
                        //più di uno
                        setVedi(result);
                    } else {
                        setVedi([result]);
                        setCodiceM(result);
                        setVediM(true);
                    }
                }
            })
            .catch((err) => {
                setVedi([]);
                setCodiceM({ codice: prodotto });
                setVediM(true);
            });
    };

    function getSconto() {
        API.get("sconti")
            .then((result) => {
                if (result.status) {
                    setSconto({
                        revisionato: parseInt(
                            result.data.filter(
                                (v) => v.tipo === "revisionato"
                            )[0].sconto
                        ),
                        nuovo: parseInt(
                            result.data.filter((v) => v.tipo === "nuovo")[0]
                                .sconto
                        ),
                        revisionato_bosch: parseInt(
                            result.data.filter(
                                (v) => v.tipo === "revisionato_bosch"
                            )[0].sconto
                        ),
                    });
                }
            })
            .catch((err) => console.error(err));
    }

    return (
        <div
            className={`${
                IsDark() ? "bg-dark" : "bg-white"
            } p-2 rounded shadow mt-2`}
        >
            <ModalProdotto
                vedi={vediM}
                chiudi={() => setVediM(false)}
                dati={codiceM}
                newSearch={newCerca}
                sconto={sconto}
            />

            <Table striped={false}>
                <Table.Thead>
                    <tr>
                        <th>codice</th>
                        <th>pronte</th>
                        <th>da fare</th>
                    </tr>
                </Table.Thead>
                <Table.Tbody>
                    {vedi.map((v, i) => (
                        <tr
                            key={i}
                            onClick={() => {
                                setCodiceM(v);
                                setVediM(true);
                            }}
                            className="cursor-pointer"
                        >
                            <td>{v.codice}</td>
                            <td>
                                {v.Nuovo + v.Revisionato + v.Revisionato_Bosch}
                            </td>
                            <td>{v.Da_revisionare + v.In_lavorazione}</td>
                        </tr>
                    ))}
                </Table.Tbody>
            </Table>
        </div>
    );
};

export default Lista;
