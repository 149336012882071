import React, { useRef, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import FloatingInput from "../inputs/FloatingInput";
import Switch from "../inputs/Switch";
import API from "../../API";
import { toast } from "react-hot-toast";
import VocinaCat from "./VocinaCat";

/* 
    cocco di dio manca il tipo -> revisionato, nuovo, revisionato Bosch
*/

const ClienteModal = ({ vedi, dati, chiudi }) => {
    const [userSconti, setUserSconti] = useState({});
    const [scontiId, setScontiId] = useState({});

    useEffect(() => {
        if (vedi) {
            getSconti();
        }
    }, [vedi]);

    const inputs = useRef();

    //converte in formato accettabile per il programma
    const elaborate = (data) => {
        const vals = {
                revisionato: {},
                nuovo: {},
                revisionato_bosch: {},
            },
            scontiID = {
                revisionato: {},
                nuovo: {},
                revisionato_bosch: {},
            };

        for (const voce of data) {
            vals[voce.tipo][voce.categoria] = voce.sconto;
            scontiID[voce.tipo][voce.categoria] = voce.id;
        }

        setUserSconti(vals);
        setScontiId(scontiID);
    };

    //ottiene gli sconti dal DB
    function getSconti() {
        API.get("scontistiche", dati.id)
            .then((result) => {
                console.log("scontistiche API", result);
                elaborate(result.data);
            })
            .catch(() =>
                toast.error("Non sono riuscito ad ottenere gli sconti")
            );
    }

    const disableAtt = (val) => {
        API.put("account", dati.id, { attivo: (val === true ? "1" : "0") })
            .then((result) => {
                if (result.status)
                    toast.success(
                        `Account ${
                            val === true ? "attivato" : "disattivato"
                        } correttamente`
                    );
                else toast.error("Impossibile cambiare lo stato dell'account");
            })
            .catch((err) => toast.error(`Errore: ${err}`));
    };

    return (
        <Modal
            centered
            scrollable
            fullscreen={true}
            show={vedi}
            onHide={chiudi}
            enforceFocus={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{dati.azienda}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex gap-2 justify-content-between mb-3">
                    <p className="mb-0">Attiva account</p>
                    <Switch
                        name="attivo"
                        id="attivo"
                        checked={dati.attivo === "1" ? true : false}
                        onChange={disableAtt}
                    />
                </div>
                <div className="mb-3">
                    <h5>Scontistica</h5>
                    <p className="mb-0">
                        Imposta sconti per questo utente basato sulle categorie
                        del sito
                    </p>
                    <sup className="text-secondary">
                        N.B. Gli sconti vanno in percentuale
                    </sup>
                </div>
                <div
                    className="d-flex gap-3 justify-content-evenly flex-wrap"
                    ref={inputs}
                >
                    <div className="border border-1 border-secondary p-2 rounded flex-grow-1">
                        <p className="mb-0">Pompe</p>
                        <hr></hr>
                        {[
                            "Common Rail",
                            "VP",
                            "VE",
                            "in linea",
                            "Bassa pressione",
                        ].map((v, i) => (
                            <VocinaCat
                                key={i}
                                cat={v}
                                voci={userSconti}
                                id={scontiId}
                                update={getSconti}
                            />
                        ))}
                    </div>
                    <div className="border border-1 border-secondary p-2 rounded flex-grow-1">
                        <p className="mb-0">Iniettori pompa</p>
                        <hr></hr>
                        {["PDE", "PLD", "XPI / HPI"].map((v, i) => (
                            <VocinaCat
                                key={i}
                                cat={v}
                                voci={userSconti}
                                id={scontiId}
                                update={getSconti}
                            />
                        ))}
                    </div>
                    <div className="border border-1 border-secondary p-2 rounded flex-grow-1">
                        <p className="mb-0">Iniettori</p>
                        <hr></hr>
                        {["Elettronici", "Meccanici", "ADBlue"].map((v, i) => (
                            <VocinaCat
                                key={i}
                                cat={v}
                                voci={userSconti}
                                id={scontiId}
                                update={getSconti}
                            />
                        ))}
                    </div>
                    <div className="border border-1 border-secondary p-2 rounded flex-grow-1">
                        <p className="mb-0">Centraline</p>
                        <hr></hr>
                        {["vendita"].map((v, i) => (
                            <VocinaCat
                                key={i}
                                cat={v}
                                voci={userSconti}
                                id={scontiId}
                                update={getSconti}
                            />
                        ))}
                    </div>
                </div>
            </Modal.Body>
            {/* 
            <Modal.Footer className="d-flex justify-content-evenly">
                <div className="cursor-pointer text-danger" onClick={chiudi}>
                    annulla
                </div>
                <div className="cursor-pointer text-primary">conferma</div>
            </Modal.Footer> */}
        </Modal>
    );
};

export default ClienteModal;

/* 

backups

<div
                    className="d-flex gap-3 justify-content-evenly flex-wrap"
                    ref={inputs}
                >
                    <div className="border border-1 border-secondary p-2 rounded flex-grow-1">
                        <p className="mb-0">Pompe</p>
                        <hr></hr>
                        {[
                            "Common Rail",
                            "VP",
                            "VE",
                            "in linea",
                            "Bassa pressione",
                        ].map((v, i) => (
                            <FloatingInput name={v} label={v} key={i} />
                        ))}
                    </div>
                    <div className="border border-1 border-secondary p-2 rounded flex-grow-1">
                        <p className="mb-0">Iniettori pompa</p>
                        <hr></hr>
                        {["PDE", "PLD", "XPI / HPI"].map((v, i) => (
                            <FloatingInput name={v} label={v} key={i} />
                        ))}
                    </div>
                    <div className="border border-1 border-secondary p-2 rounded flex-grow-1">
                        <p className="mb-0">Centraline</p>
                        <hr></hr>
                        {["vendita"].map((v, i) => (
                            <FloatingInput name={v} label={v} key={i} />
                        ))}
                    </div>
                </div>

*/
