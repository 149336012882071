import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Alerter = ({ vedi, chiudi, errore }) => {
    const navigate = useNavigate();

    return (
        <Modal size="md" centered show={vedi} onHide={chiudi} backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>Attenzione</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    C'è stato un errore, puoi riprovare o annullare e provare
                    più tardi se il problema persiste
                </p>
                <sup className="text-danger">{errore}</sup>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-evenly">
                <div className="cursor-pointer text-danger" onClick={() => navigate("/")}>
                    <span>Annulla</span>
                </div>
                <div className="cursor-pointer text-primary" onClick={() => window.location.reload()}>
                    <span>Riprova</span>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default Alerter;
