import React from "react";
import IsDark from "../IsDark";
import { Tab, Nav } from "react-bootstrap";
import Infos from "../components/users/Infos";
import Indirizzi from "../components/users/Indirizzi";

const Users = () => {
    return (
        <section
            className={
                "p-3 container d-flex gap-4 shadow rounded flex-column flex-md-row" +
                (IsDark() ? " bg-dark" : " bg-white")
            }
        >
            <Tab.Container defaultActiveKey="utente">
                <Nav variant="pills" className="d-flex flex-md-column text-start justify-content-md-start justify-content-evenly">
                    <Nav.Item className="text-nowrap">
                        <Nav.Link eventKey="utente">utente</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="text-nowrap">
                        <Nav.Link eventKey="address">indirizzi</Nav.Link>
                    </Nav.Item>
                </Nav>

                <Tab.Content className="w-100">
                    <Tab.Pane eventKey="utente">
                        <Infos />
                    </Tab.Pane>
                    <Tab.Pane eventKey="address">
                        <Indirizzi />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </section>
    );
};

export default Users;
