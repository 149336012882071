import React, { useState, useEffect } from "react";
import Container from "../components/Container";
import API from "../API";
import { Card } from "react-bootstrap";
import { motion } from "framer-motion";
import ClienteModal from "../components/admin/ClienteModal";

const AdminArea = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userList, setUserList] = useState([]);
    //parte modal
    const [datiM, setDatiM] = useState({});
    const [vediM, setVediM] = useState(false);

    useEffect(() => {
        trylogin();

        return () => {
            setIsLoggedIn(false);
        };
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            getUserList();
        }
    }, [isLoggedIn]);

    const trylogin = () => {
        if (localStorage.getItem("cliente") !== null) {
            const dati = JSON.parse(localStorage.getItem("cliente")).dati;
            API.hashLog(dati)
                .then((data) => {
                    if (data.status) {
                        setIsLoggedIn(true);
                    } else setIsLoggedIn(false);
                })
                .catch((err) => {
                    setIsLoggedIn(false);
                });
        }
    };

    function getUserList() {
        API.get("vistaUser")
            .then((result) => {
                if (Array.isArray(result.data)) setUserList(result.data);
                else setUserList([result.data]);
            })
            .catch((err) => console.log(err));
    }

    if (isLoggedIn)
        return (
            <Container className="text-start">
                <ClienteModal
                    vedi={vediM}
                    dati={datiM}
                    chiudi={() => {
                        getUserList()
                        setVediM(false)
                    }}
                />
                <h5>Gestione clienti</h5>
                <div className="d-flex flex-justify-center p-2 gap-3 flex-wrap">
                    {userList.map((user, i) => (
                        <motion.div
                            className="cursor-pointer shadow"
                            whileHover={{
                                scale: 1.05,
                            }}
                            whileTap={{
                                scale: 0.95,
                            }}
                            key={i}
                            onClick={() => {
                                setDatiM(user);
                                setVediM(true);
                            }}
                        >
                            <Card>
                                <Card.Header>
                                    <Card.Title>{user.azienda}</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <ul className="list-group">
                                        <li className="list-group-item">
                                            {user.nome} {user.cognome}
                                        </li>
                                        <li class="list-group-item">
                                            Totale ordini: X
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card>
                        </motion.div>
                    ))}
                </div>
            </Container>
        );
};

export default AdminArea;
