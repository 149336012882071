import React from 'react'

/**
 * @param {string} name     Nome per questo input
 * @param {string} id       Id input
 * @param {string} label    Label, usando anche per placeholder
 * @param {string} classes  Eventuali classi aggiuntive, in formato "classe esempio vero"
 * @param {bool}   margin   Se impostare il margin bottom di 3 oppure no
 * @param {bool}   disabled Se attivare o meno l'input
 * @param {Array}  valori   i valori in array, con chiave -> valore: es <option value={VALORE.CHIAVE}>{VALORE.VALORE}</option>
 * @param {string} valore   Eventuale valore predefinito
 * @param {string} valoreS  Il valore state, segue il cambio dello stato
 * @param {object} style    Eventuali stili da inserire IN CAMELCASE
 * @returns rendered
 */

/**
 * @event onChange Gestisce gli onchanges
 */

/* 
  Use Example
    <FloatingSelect
        id="mattopome"
        name="mattopome"
        label="Mattina o Pomeriggio"
        valori={[
            {mattina: "mattina"},
            {pomeriggio: "pomeriggio"}
        ]}
    />
*/

const FloatingSelect = (props) => {
    return (
        <div className={"form-floating " + ((props.margin) ? "mb-3 " : " ") + ((props.classes) ? props.classes : "")} style={props.style}>
          <select className='form-select' name={props.name} id={props.id} value={props.valoreS} defaultValue={props.valore} disabled={props.disabled} onChange={(e) => {
            if (typeof props.onChange != "undefined")
              props.onChange(e)
          }}>
            {
                props.valori.map((elm, i) => {
                    return (
                        <option key={i} value={Object.keys(elm)[0]}>{Object.values(elm)[0]}</option>
                    )
                })
            }
          </select>
          <label htmlFor={props.id}>{props.label}</label>
        </div>
    )
}

export default FloatingSelect

FloatingSelect.defaultProps = {
  margin: true,
  disabled: false,
  style: {}
}

/* 
  Changelog
    - [2023-02-14] [
      -Aggiunto opzionale Style
    ]
    - [2023-01-20] {
        - Corretto l'erroe: non potevi cambiare stato senza un onchange, cosi messo props valoreS che segue uno stato
        - Cambiato defaultValue in value -> il primo gestisce l'iniziale, il secondo il valore anche nel cambiamento
      }
    - [2023-01-17] Aggiunti eventi
*/