import React from "react";
import IsDark from "../IsDark";
import Switch from "../components/inputs/Switch";
import Button from "../components/inputs/Button";
import { useNavigate } from "react-router-dom";

const Settings = () => {
    const navigate = useNavigate();
    const disconnetti = () => {
        localStorage.removeItem("cliente");
        navigate("/");
        window.location.reload();
    };

    return (
        <section
            className={`container text-start ${
                IsDark() ? "bg-dark" : "bg-white"
            } p-3 shadow rounded`}
            style={styles.cont}
        >
            <h4>Impostazioni</h4>
            <sup className="text-secondary">Versione: {window.version}</sup>
            <p>le impostazioni saranno cambiate solo su questo dispositivo</p>
            <h5>Tema</h5>
            <SettingsBlock>
                <p className="mb-0">Tema scuro</p>
                <Switch
                    onChange={(v) => {
                        localStorage.setItem("tema_scuro", v);
                        window.location.reload();
                    }}
                    checked={
                        localStorage.getItem("tema_scuro") === "true"
                            ? true
                            : false
                    }
                />
            </SettingsBlock>
            <SettingsBlock>
                <div>
                    <p className="mb-0">Tema automatico</p>
                    <sup className="text-secondary">
                        Seguira quello del dispositivo
                    </sup>
                </div>
                <Switch
                    onChange={(v) => {
                        localStorage.setItem("tema_auto", v);
                        window.location.reload();
                    }}
                    checked={
                        localStorage.getItem("tema_auto") === "true"
                            ? true
                            : false
                    }
                />
            </SettingsBlock>
            <h5>Notifiche</h5>
            <SettingsBlock>
                <p className="mb-0">Abilita notifiche non necessarie</p>
                <Switch
                    onChange={(v) => localStorage.setItem("notifiche", v)}
                    checked={
                        localStorage.getItem("notifiche") === "true"
                            ? true
                            : false
                    }
                />
            </SettingsBlock>
            <div className="text-center">
                <Button
                    variant="indian"
                    className="w-100 mt-3"
                    onClick={disconnetti}
                >
                    disconnettiti
                </Button>
            </div>
        </section>
    );
};

export default Settings;

const styles = {
    cont: {
        maxWidth: "400px",
    },
};

const SettingsBlock = ({ children }) => {
    return (
        <div className="d-flex justify-content-between align-items-center mt-3 mb-3 gap-4">
            {children}
        </div>
    );
};
