import React, { useRef, useState, useEffect } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import FloatingInput from "../inputs/FloatingInput";
import Button from "../inputs/Button";
import API from "../../API";
import Table from "../Table";
import { toast } from "react-hot-toast";

const VocinaCat = ({ cat, voci, id, update }) => {
    const [sconti, setSconti] = useState({
        nuovo: 0,
        revisionato: 0,
        revisionato_bosch: 0,
    });

    const inputs = useRef();

    useEffect(() => {
        setCorrectSconti();
    }, [voci]);

    function setCorrectSconti() {
        Object.entries(voci).forEach((val) => {
            //val[], 0 -> key, 1 -> valori
            setSconti((prev) => {
                return { ...prev, [val[0]]: val[1][cat] };
            });
        });
    }

    async function send() {
        const input = inputs.current.querySelectorAll("input");
        let errore = false;
        
        for (const inp of input) {
            let send = {
                sconto: inp.value
            };
            let id_put = Object.entries(id[inp.name]).filter(v => v[0] === cat)[0][1]
            try {
                await API.put("scontistiche", id_put, send)
            } catch (error) {
                toast.error(error)
                errore = true;
            }
        }

        if (!errore) {
            update()
        }
    }

    return (
        <OverlayTrigger
            trigger="click"
            placement="auto"
            rootClose={true}
            overlay={
                <Popover id={"cat_" + cat} className="border-primary shadow">
                    <Popover.Header>Sconti per {cat}</Popover.Header>
                    <Popover.Body>
                        <div ref={inputs}>
                            <FloatingInput
                                className="border border-1 border-primary rounded"
                                name="revisionato"
                                label="Revisionato"
                                defaultValue={sconti.revisionato}
                            />
                            <FloatingInput
                                className="border border-1 border-primary rounded"
                                name="revisionato_bosch"
                                label="Revisionato Bosch"
                                defaultValue={sconti.revisionato_bosch}
                            />
                            <FloatingInput
                                className="border border-1 border-primary rounded"
                                name="nuovo"
                                label="Nuovo"
                                defaultValue={sconti.nuovo}
                            />
                        </div>
                        <div className="w-100">
                            <Button onClick={send} className="w-100">
                                conferma
                            </Button>
                        </div>
                    </Popover.Body>
                </Popover>
            }
        >
            <div className="mt-3 mb-3 cursor-pointer border p-2 border-primary rounded">
                <span>{cat}</span>
                <Table striped={false} className="text-center">
                    <Table.Thead>
                        <tr>
                            <th>Rev</th>
                            <th>Rev B</th>
                            <th>Nuovo</th>
                        </tr>
                    </Table.Thead>
                    <Table.Tbody>
                        <tr>
                            <td>{sconti.revisionato}</td>
                            <td>{sconti.revisionato_bosch}</td>
                            <td>{sconti.nuovo}</td>
                        </tr>
                    </Table.Tbody>
                </Table>
            </div>
        </OverlayTrigger>
    );
};

export default VocinaCat;
