import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const Box = ({ children, url, icona }) => {
    const navigate = useNavigate();
    return (
        <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            style={styles.box}
            className="shadow p-3 align-center bg-primary text-white rounded d-flex flex-column justify-content-between cursor-pointer"
            onClick={() => navigate(`/${url}`)}
        >
            <span className="material-symbols-outlined" style={styles.icona}>
                {icona}
            </span>
            <span>{children}</span>
        </motion.div>
    );
};

export default Box;

const styles = {
    box: {
        height: "150px",
        width: "150px",
    },
    icona: {
        fontSize: "80px",
    },
};
