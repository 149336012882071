import React, { useState, useEffect } from "react";
import Table from "../Table";
import API from "../../API";

const PriceTable = ({ dati, loaded, exportSconti, exportCarcasse }) => {
    const [vista, setVista] = useState({
        //vista prezzi
        revisionato: {},
        revisionato_bosch: {},
        nuovo: {},
        carcassa: 0,
    });
    const [categ, setCateg] = useState({}); //categorie estratte
    const [sconti, setSconti] = useState({
        //sconti per tipo
        revisionato: {},
        nuovo: {},
        revisionato_bosch: {},
    });
    const [scontiVista, setScontiVista] = useState({
        //vista prezzo scontato
        revisionato: "",
        nuovo: "",
        revisionato_bosch: "",
    });
    const [loadedSconti, setLoadedSconti] = useState(false); //se api ha finito di caricare

    //avvio del componente, ad ottenimento di dati nuovi
    useEffect(() => {
        if (dati) {
            let temp = dati;
            for (const key of ["revisionato", "revisionato_bosch", "nuovo"]) {
                if (typeof temp[key] === "undefined") {
                    temp[key] = {};
                }
            }

            //filtro per trovare la carcassa
            let carcassaFilter = Object.values(temp).filter(
                (v) => typeof v.carcassa !== "undefined"
            );
            if (carcassaFilter.length > 0) {
                temp.carcassa = carcassaFilter[0].carcassa;
            } else {
                temp.carcassa = 0;
            }

            setVista(temp);

            let CategoriaFilter = Object.values(temp).filter(
                (v) => typeof v.categoria !== "undefined"
            );
            if (CategoriaFilter.length > 0) {
                setCateg(CategoriaFilter[0].categoria);
            } else {
                setCateg({});
            }
        } else {
            setVista({
                revisionato: {},
                revisionato_bosch: {},
                nuovo: {},
                carcassa: 0,
            });
            setSconti({
                revisionato: {},
                nuovo: {},
                revisionato_bosch: {},
            });
            setLoadedSconti(false);
            setScontiVista({
                //vista prezzo scontato
                revisionato: "",
                nuovo: "",
                revisionato_bosch: "",
            });
        }
    }, [dati]);

    useEffect(() => {
        if (Object.values(categ).length > 0) {
            getSconti();
        }
    }, [categ]);

    const elabSconti = (data) => {
        let firstCut = data.filter((v) => v.categoria === categ.cat),
            second = {},
            carcassa = {};

        for (const val of firstCut) {
            second[val.tipo] = val.sconto;
        }

        setSconti(second);

        let vistaS = {
            revisionato: "",
            nuovo: "",
            revisionato_bosch: "",
        };
        //creo scontiVista
        if (
            typeof vista.revisionato.prezzo !== "undefined" &&
            vista.revisionato.prezzo > 0
        ) {
            vistaS.revisionato = parseFloat(
                vista.revisionato.prezzo -
                    (vista.revisionato.prezzo * second.revisionato) / 100
            ).toFixed(2);
            if (
                vista.revisionato.carcassa !== "undefined" &&
                vista.revisionato.carcassa > 0
            )
                carcassa.revisionato = vista.revisionato.carcassa;
            else carcassa.revisionato = false;
        } else carcassa.revisionato = false;
        if (
            typeof vista.revisionato_bosch.prezzo !== "undefined" &&
            vista.revisionato_bosch.prezzo > 0
        ) {
            vistaS.revisionato_bosch = parseFloat(
                vista.revisionato_bosch.prezzo -
                    (vista.revisionato_bosch.prezzo *
                        second.revisionato_bosch) /
                        100
            ).toFixed(2);
            if (
                vista.revisionato_bosch.carcassa !== "undefined" &&
                vista.revisionato_bosch.carcassa > 0
            )
                carcassa.revisionato_bosch = vista.revisionato_bosch.carcassa;
            else carcassa.revisionato_bosch = false;
        } else carcassa.revisionato_bosch = false;
        if (
            typeof vista.nuovo.prezzo !== "undefined" &&
            vista.nuovo.prezzo > 0
        ) {
            vistaS.nuovo = parseFloat(
                vista.nuovo.prezzo - (vista.nuovo.prezzo * second.nuovo) / 100
            ).toFixed(2);
            if (
                vista.nuovo.carcassa !== "undefined" &&
                vista.nuovo.carcassa > 0
            )
                carcassa.nuovo = vista.nuovo.carcassa;
            else carcassa.nuovo = false;
        } else carcassa.nuovo = false;

        setScontiVista(vistaS);
        setLoadedSconti(true);
        exportSconti(vistaS);
        exportCarcasse(carcassa);
    };

    function getSconti() {
        setLoadedSconti(false);
        let id_user = JSON.parse(localStorage.getItem("cliente")).dati.id
        API.get("scontistiche", id_user)
            .then((result) => {
                elabSconti(result.data);
            })
            .catch((err) => {
                setLoadedSconti(true);
            });
    }

    return (
        <div className="p-1">
            <h5>Prezzi</h5>
            <Table striped={false} className="align-middle">
                <Table.Thead>
                    <tr>
                        <th>Tipo</th>
                        <th className="text-end">P.List</th>
                        <th className="text-end">Sconto</th>
                        <th className="text-end">P.Scontato</th>
                    </tr>
                </Table.Thead>
                <Table.Tbody>
                    <tr>
                        <td>Revisionato</td>
                        <td className="text-end">
                            {!loaded ? (
                                <Placeholder />
                            ) : (
                                vista.revisionato?.prezzo
                            )}
                        </td>
                        <td className="text-end">
                            {!loadedSconti ? (
                                <Placeholder />
                            ) : (
                                `${sconti.revisionato} %`
                            )}
                        </td>
                        <td className="text-end">
                            {!loadedSconti ? (
                                <Placeholder />
                            ) : (
                                scontiVista.revisionato
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td>Revisionato Bosch</td>
                        <td className="text-end">
                            {!loaded ? (
                                <Placeholder />
                            ) : (
                                vista.revisionato_bosch?.prezzo
                            )}
                        </td>
                        <td className="text-end">
                            {!loadedSconti ? (
                                <Placeholder />
                            ) : (
                                `${sconti.revisionato_bosch} %`
                            )}
                        </td>
                        <td className="text-end">
                            {!loadedSconti ? (
                                <Placeholder />
                            ) : (
                                scontiVista.revisionato_bosch
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p className="mb-0">Carcassa</p>
                            <sup className="text-secondary">Ove necessaria</sup>
                        </td>
                        <td className="text-end">
                            {!loaded ? <Placeholder /> : vista.carcassa}
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Nuovo</td>
                        <td className="text-end">
                            {!loaded ? <Placeholder /> : vista.nuovo?.prezzo}
                        </td>
                        <td className="text-end">
                            {!loadedSconti ? (
                                <Placeholder />
                            ) : (
                                `${sconti.nuovo} %`
                            )}
                        </td>
                        <td className="text-end">
                            {!loadedSconti ? (
                                <Placeholder />
                            ) : (
                                scontiVista.nuovo
                            )}
                        </td>
                    </tr>
                </Table.Tbody>
            </Table>
        </div>
    );
};

export default PriceTable;

PriceTable.defaultProps = {
    dati: false,
};

const Placeholder = () => {
    return (
        <span
            style={styles.placeholder}
            className="rounded placeholder-wave placeholder-lg bg-secondary d-inline-block mx-auto"
        ></span>
    );
};

const styles = {
    placeholder: {
        minWidth: "80px",
    },
};
