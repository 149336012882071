import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import IsDark from "../../IsDark";

const FloatingTextArea = ({ id, label, margin, className, onChange, onKeyDown }) => {
    return (
        <FloatingLabel
            controlId={id !== false ? id : `id_${label}`}
            label={label}
            className="textAreaFloating"
        >
            <Form.Control
                as="textarea"
                placeholder={`Spazio Label`}
                style={styles.textarea}
                className={`${IsDark() ? "bg-dark" : "bg-white" } ${margin && "mb-3"} ${className && className}`}
                onKeyDown={(val) => {
                    if (typeof onKeyDown !== "undefined")
                        onKeyDown(val.target.value)
                }}
                onChange={(val) => {
                    if (typeof onChange !== "undefined")
                        onChange(val.target.value)
                }}
            />
        </FloatingLabel>
    );
};

export default FloatingTextArea;

FloatingTextArea.defaultProps = {
    label: "Imposta il label dai props",
    margin: true,
    className: false
};

const styles = {
    textarea: { height: "100px", resize: "none" }
};
