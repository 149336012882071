import React from "react";
import Table from "../Table";

const PrezziHandler = ({ sconto, prezzo, priceLoaded }) => {
    return (
        <Table striped={false}>
            <Table.Tbody>
                {/* da aggiungere se prezzo > 0 € */}
                <tr>
                    <td>Revisionato</td>
                    <td>
                        {priceLoaded ? (
                            <span
                                className={`${
                                    typeof sconto.revisionato !== "undefined" &&
                                    sconto.revisionato > 0 &&
                                    "text-decoration-line-through text-secondary"
                                }`}
                            >
                                {prezzo.revisionato?.prezzo > 0 &&
                                    `${prezzo.revisionato.prezzo} €`}
                            </span>
                        ) : (
                            <span
                                style={styles.placeholder}
                                className="rounded placeholder placeholder-wave bg-secondary d-inline-block"
                            ></span>
                        )}
                    </td>
                    <td>
                        {(priceLoaded &&
                            sconto.revisionato &&
                            prezzo.revisionato.prezzo > 0) > 0 && (
                            <span>
                                {parseFloat(
                                    prezzo.revisionato.prezzo -
                                        (prezzo.revisionato.prezzo *
                                            sconto.revisionato) /
                                            100
                                ).toFixed(2)}{" "}
                                €
                            </span>
                        )}
                    </td>
                </tr>
                <tr>
                    <td>Revisionato Bosch</td>
                    <td>
                        {priceLoaded ? (
                            <span
                                className={`${
                                    typeof sconto.revisionato_bosch !==
                                        "undefined" &&
                                    sconto.revisionato_bosch > 0 &&
                                    "text-decoration-line-through text-secondary"
                                }`}
                            >
                                {prezzo.revisionato_bosch?.prezzo > 0 &&
                                    `${prezzo.revisionato_bosch?.prezzo} €`}
                            </span>
                        ) : (
                            <span
                                style={styles.placeholder}
                                className="rounded placeholder placeholder-wave bg-secondary d-inline-block"
                            ></span>
                        )}
                    </td>
                    <td>
                        {priceLoaded &&
                            sconto.revisionato_bosch > 0 &&
                            prezzo.revisionato_bosch.prezzo > 0 && (
                                <span>
                                    {parseFloat(
                                        prezzo.revisionato_bosch.prezzo -
                                            (prezzo.revisionato_bosch.prezzo *
                                                sconto.revisionato_bosch) /
                                                100
                                    ).toFixed(2)}{" "}
                                    €
                                </span>
                            )}
                    </td>
                </tr>
                <tr>
                    <td>Nuovo</td>
                    <td>
                        {priceLoaded ? (
                            <span
                                className={`${
                                    typeof sconto.nuovo !== "undefined" &&
                                    sconto.nuovo > 0 &&
                                    "text-decoration-line-through text-secondary"
                                }`}
                            >
                                {prezzo.nuovo?.prezzo > 0 &&
                                    `${prezzo.nuovo.prezzo} €`}
                            </span>
                        ) : (
                            <span
                                style={styles.placeholder}
                                className="rounded placeholder placeholder-wave bg-secondary d-inline-block"
                            ></span>
                        )}
                    </td>
                    <td>
                        {priceLoaded &&
                            sconto.nuovo > 0 &&
                            prezzo.nuovo.prezzo > 0 && (
                                <span>
                                    {parseFloat(
                                        prezzo.nuovo.prezzo -
                                            (prezzo.nuovo.prezzo *
                                                sconto.nuovo) /
                                                100
                                    ).toFixed(2)}{" "}
                                    €
                                </span>
                            )}
                    </td>
                </tr>
            </Table.Tbody>
        </Table>
    );
};

export default PrezziHandler;

const styles = {
    placeholder: {
        width: "50px",
    },
};
