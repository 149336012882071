import React from "react";

const Articolo0 = () => {
    return (
        <section>
            <h2 className="text-primary">App rilasciata</h2>
            <h5 className="text-secondary">Versione 0.1.0</h5>
            <p>
                Prima versione dell'applicazione dei fornitori con scontistiche
                personalizzate.
            </p>
            <p>
                Ogni errore dell'applicazione può essere segnalato via email a{" "}
                <a href="mailto:support@officinaveneziani.com" target="_blank">
                    support@officinaveneziani.com
                </a>
                .
            </p>
            <p>
                Ogni ordine verrà processato dal nostro team, eventuali accordi
                commerciali resteranno invariati.
            </p>
        </section>
    );
};

export default Articolo0;
