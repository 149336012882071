import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";

/**
 *
 * @param {string}      id              Id da usare per floating
 * @param {string}      label           Il testo volante
 * @param {string}      className       Eventuali classi da applicare
 * @param {bool}        margin          Se aggiungere il margine in basso
 * @param {string}      type            Tipo di input, text, numeric ecc
 * @param {string}      defaultValue    Il valore di default per l'input
 * @param {null|bool}   valid           Se null non attiva la funzione, se no true|false per validità
 * @param {string}      invalidText     Testo da mostrare se input non valido
 * @returns Campo di input con floating
 *
 * Event List
 * @onChange
 * @onKeyDown
 * @onEnter
 */

const FloatingInput = (props) => {
    return (
        <FloatingLabel
            controlId={(props.id) ? props.id : crypto.randomUUID()}
            label={props.label}
            className={`${props.className} ${props.margin && "mb-3"}`}
        >
            <Form.Control
                type={props.type}
                autoComplete="off"
                placeholder={
                    props.placeholder
                        ? props.placeholder
                        : props.label
                }
                name={props.name}
                onChange={(e) => {
                    if (typeof props.onChange !== "undefined")
                        props.onChange(e.target.value);
                }}
                onKeyDown={(e) => {
                    if (typeof props.onKeyDown !== "undefined")
                        props.onKeyDown(e.target.value);
                    if (typeof props.onEnter !== "undefined" && e.key === "Enter")
                        props.onEnter(e.target.value)
                }}
                defaultValue={props.defaultValue}
                className={`${
                    props.valid !== null &&
                    (props.valid ? "is-valid" : "is-invalid")
                }`}
            />
            {props.invalidText && (
                <Form.Control.Feedback type="invalid" className="text-start">
                    {props.invalidText}
                </Form.Control.Feedback>
            )}
        </FloatingLabel>
    );
};

export default FloatingInput;

FloatingInput.defaultProps = {
    id: crypto.randomUUID(),
    label: "Placeholder Label Props",
    className: "",
    margin: true,
    type: "text",
    name: "Placeholder_name",
    defaultValue: "",
    valid: null,
    invalidText: false
};
