import React from 'react'
import "./Switch.css"

//una seconda versione dello switch

/**
 * Nuovo switch, sfrutta className switch2 in App.css
 * @param {string}  name            Il nome di questo input
 * @param {string}  id              Id, se vuoto viene usato random
 * @param {string}  className       eventuali classi aggiuntive
 * @param {bool}    disabled        Se deve essere disabilitato o meno
 * @param {bool}    required
 * @param {bool}    checked         Se impostare a checked
 * 
 * Events
 * @onChange al click che cambia valore
 */

const Switch = (props) => {
    return (
        <input 
            type='checkbox'
            className={'switch ' + ((props.className) ? props.className : "")}
            name={props.name}
            id={props.id}
            disabled={props.disabled}
            defaultChecked={props.checked}
            onChange={(e) => {
                if (typeof props.onChange != "undefined")
                    props.onChange(e.target.checked)
            }}
        />
    )
}

export default Switch

Switch.defaultProps = {
    className: false,
    required: false,
    id: crypto.randomUUID(),
    disabled: false,
    checked: false
}