import React from "react";
import News from "./News";
import Box from "./Box";

const BottomPart = () => {
    return (
        <div className="d-flex justify-content-evenly mt-5 gap-4 flex-wrap container">
            <News />

            <div className="d-flex gap-5 flex-wrap">
                <Box url="ordini" icona="work_history">Storico</Box>
                <Box url="carrello" icona="shopping_cart_checkout">Carrello</Box>
            </div>
        </div>
    );
};

export default BottomPart;
