import React, { useState, useEffect } from "react";
import API from "../API";
import Container from "../components/Container";
import FloatingSelect from "../components/inputs/FloatingSelect";
import TabellaOrdini from "../components/ordini/TabellaOrdini";

const Ordini = () => {
    const [ordini, setOrdini] = useState([]); //gli ordini ottenuti da API
    const [filtro, setFiltro] = useState("mesi"); //filtro per la vista
    const [vistaOrdini, setVistaOrdini] = useState([]); //ordini da visualizzare in base al filtro
    const [loadedDati, setLoadedDati] = useState(false);

    useEffect(() => {
        getOrderList();

        return () => {
            setLoadedDati(false);
            setVistaOrdini([]);
            setFiltro("mesi");
            setOrdini([]);
        };
    }, []);

    useEffect(() => {
        if (ordini.length > 0) filtra();
    }, [ordini]);

    function getOrderList() {
        setLoadedDati(false);
        const userId = JSON.parse(localStorage.getItem("cliente")).dati.id;
        API.get("riepilogo_ordini", userId)
            .then((result) => {
                let val;
                if (Array.isArray(result.data)) val = result.data;
                else val = [result.data];

                setOrdini(val);
                setLoadedDati(true);
            })
            .catch((err) => console.log(err));
    }

    function compileMesi(qta) {
        //substring 0,7 -> data 5,7 mese
        const lastOrder = ordini[0].data.substring(0, 7),
            date = new Date(lastOrder),
            returner = [lastOrder];

        for (let i = 1; i < qta; i++) {
            let temp = date.setMonth(date.getMonth() - 1);
            returner.push(new Date(temp).toISOString().substring(0, 7));
        }

        return returner;
    }

    const filtra = (mode = "mesi") => {
        let filtered = [],
            mesi = compileMesi(3),
            anno = compileMesi(12);

        if (isNaN(parseInt(mode))) {
            //è mesi, anno, tutti
            switch (mode) {
                case "mesi":
                    filtered = ordini.filter((v) =>
                        mesi.includes(v.data.substring(0, 7))
                    );
                    break;
                case "anno":
                    filtered = ordini.filter((v) =>
                        anno.includes(v.data.substring(0, 7))
                    );
                    break;
                case "tutti":
                    filtered = ordini;
                    break;
                default:
                    break;
            }
        } else {
            filtered = ordini.filter(
                (v) => v.data.substring(0, 4) === String(mode)
            );
        }

        setVistaOrdini(filtered);
    };

    const optionsSelect = [
        { mesi: "Ultimi 3 mesi" },
        { anno: "Questo anno" },
        { tutti: "Tutte le date" },
    ];

    for (let i = 2023; i <= new Date().getFullYear(); i++) {
        optionsSelect.push({
            [i]: `Nel ${i}`,
        });
    }

    return (
        <React.Fragment>
            <Container>
                <div className="d-flex justify-content-end">
                    <div>
                        <FloatingSelect
                            name="filtro"
                            id="filtro"
                            valori={optionsSelect}
                            label="Filtro"
                            margin={false}
                            onChange={(v) => {
                                setFiltro(v.target.value);
                                filtra(v.target.value);
                            }}
                        />
                    </div>
                </div>
            </Container>

            {loadedDati && <TabellaOrdini dati={vistaOrdini} />}
        </React.Fragment>
    );
};

export default Ordini;
