import React, { useState } from "react";
import "./StyleRange.css";

/**
 * Funzione di slide per conferma
 * Semplice input che usa il 100% del width parent per creare uno slide to confirm
 * @param {function}    success     Funzione da eseguire al completamento dello slide
 * @param {bool}        disabled    Se disabilitare o meno l'input
 */
const ConfirmSlider = ({ success, disabled, label }) => {
    const [valore, setValore] = useState(0);

    const listen = (e) => {
        setValore(e.target.value);
    };

    const resolver = () => {
        return new Promise((resolve) => {
            setTimeout(() => resolve(), 2);
        });
    };

    const restarter = async (val) => {
        for (let i = val; i >= 0; i--) {
            setValore(i);
            await resolver();
        }
    };

    const fine = (e) => {
        let val = e.target.value;
        if (val < 98) {
            restarter(val);
        } else {
            restarter(val);
            success();
        }
    };

    return (
        <div className="w-100 text-center">
            <input
                type="range"
                className="confirmer_range"
                value={valore}
                min={0}
                max={100}
                onChange={listen}
                onMouseUp={fine}
                onTouchEnd={fine}
                disabled={disabled}
            />
            {label && <sup className="text-secondary">slide per conferma</sup>}
        </div>
    );
};

export default ConfirmSlider;

ConfirmSlider.defaultProps = {
    success: () => console.log("Success"),
    disabled: false,
    label: false,
};
