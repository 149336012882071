import React, { useEffect, useState } from "react";
import Table from "./Table";
import { motion } from "framer-motion";
import Cart from "../Cart";

const CarrelloRapido = ({ voci }) => {
    const [totale, setTotale] = useState(0.0);
    const [iva, setiva] = useState(0.0);
    const [totaleImp, setTotaleImp] = useState(0.0);

    useEffect(() => {
        calcTot();
    }, [voci]);

    const deleteFromCart = (id) => {
        Cart.rimuovi(id);
    };

    function calcTot() {
        let tot = 0.0;
        for (const voce of voci) {
            tot += parseFloat(voce.prezzo * voce.qta);
        }
        //aggiungo la spedizione
        tot = tot + 20;
        setTotale(floater(tot));
        setiva(floater(tot * 0.22));
        setTotaleImp(floater(tot + tot * 0.22));
    }

    const floater = (val) => parseFloat(val).toFixed(2);

    return (
        <Table striped={false}>
            <Table.Thead>
                <tr>
                    <th></th>
                    <th>Codice</th>
                    <th>Tipo</th>
                    <th>Qta</th>
                    <th className="text-end">Prezzo</th>
                </tr>
            </Table.Thead>
            <Table.Tbody>
                {voci.map((voce, i) => (
                    <tr key={i}>
                        <td>
                            <motion.span
                                whileHover={{
                                    scale: 1.07,
                                }}
                                whileTap={{
                                    scale: 0.93,
                                }}
                                className="material-symbols-outlined cursor-pointer align-middle text-indian"
                                onClick={deleteFromCart.bind(this, i)}
                            >
                                delete
                            </motion.span>
                        </td>
                        <td>{voce.nome}</td>
                        <td>{voce.tipo}</td>
                        <td>{voce.qta}</td>
                        <td className="text-end text-nowrap">
                            {parseFloat(voce.prezzo * voce.qta).toFixed(2)} €
                        </td>
                    </tr>
                ))}
            </Table.Tbody>
            <Table.Tfoot>
                <tr>
                    <td className="font-weight-bold" colSpan={3}>
                        Spedizione
                    </td>
                    <td colSpan={2} className="text-end text-nowrap">
                        20.00 €
                    </td>
                </tr>
                <tr>
                    <td className="font-weight-bold" colSpan={3}>
                        Totale
                    </td>
                    <td colSpan={2} className="text-end text-nowrap">
                        {totale} €
                    </td>
                </tr>
                <tr>
                    <td className="font-weight-bold" colSpan={3}>
                        Iva
                    </td>
                    <td colSpan={2} className="text-end text-nowrap">
                        {iva} €
                    </td>
                </tr>
                <tr>
                    <td className="font-weight-bold" colSpan={3}>
                        Totale con Iva
                    </td>
                    <td colSpan={2} className="text-end text-nowrap">
                        {totaleImp} €
                    </td>
                </tr>
            </Table.Tfoot>
        </Table>
    );
};

export default CarrelloRapido;
