import React from "react";
import { Card } from "react-bootstrap";
import IsDark from "../../IsDark";
import { motion } from "framer-motion";

const IndirizzoCard = ({ dati, onClick, className }) => {
    return (
        <motion.div
            className="m-3"
            whileHover={{
                scale: 1.05,
            }}
            whileTap={{
                scale: 0.95,
            }}
        >
            <Card
                style={styles.card}
                className={`cursor-pointer ${
                    IsDark() ? "bg-dark" : "bg-white"
                } shadow ${className && className}`}
                onClick={onClick}
            >
                <Card.Header>
                    <Card.Title>{dati.nome_indirizzo}</Card.Title>
                </Card.Header>
                <Card.Body className="text-start">
                    <ul
                        className={`list-group ${
                            IsDark() ? "bg-dark" : "bg-white"
                        }`}
                    >
                        {dati.azienda && (
                            <li className="list-group-item font-weight-bold">
                                {dati.azienda}
                            </li>
                        )}
                        {dati.indirizzo && (
                            <li className="list-group-item">
                                {dati.indirizzo}
                            </li>
                        )}
                        {dati.citta && (
                            <li className="list-group-item">
                                {dati.citta} {dati.provincia}
                            </li>
                        )}
                        {dati.cap && (
                            <li className="list-group-item">{dati.cap}</li>
                        )}
                        {dati.partita_iva && (
                            <li className="list-group-item">
                                {dati.partita_iva}
                            </li>
                        )}
                        {dati.codice_fiscale && (
                            <li className="list-group-item">
                                {dati.codice_fiscale}
                            </li>
                        )}
                        {dati.telefono && (
                            <li className="list-group-item">{dati.telefono}</li>
                        )}
                    </ul>
                </Card.Body>
            </Card>
        </motion.div>
    );
};

export default IndirizzoCard;

const styles = {
    card: {
        maxWidth: "300px",
    },
};
