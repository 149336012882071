import React from "react";
import "./login.css"

const LoadingPage = () => {
    return (
        <React.Fragment>
            <div className="bg-primary h-100 overflow-hidden position-relative">
                <div className="box-of-star1">
                    <div className="star star-position1"></div>
                    <div className="star star-position2"></div>
                    <div className="star star-position3"></div>
                    <div className="star star-position4"></div>
                    <div className="star star-position5"></div>
                    <div className="star star-position6"></div>
                    <div className="star star-position7"></div>
                </div>
                <div className="box-of-star2">
                    <div className="star star-position1"></div>
                    <div className="star star-position2"></div>
                    <div className="star star-position3"></div>
                    <div className="star star-position4"></div>
                    <div className="star star-position5"></div>
                    <div className="star star-position6"></div>
                    <div className="star star-position7"></div>
                </div>
                <div className="box-of-star3">
                    <div className="star star-position1"></div>
                    <div className="star star-position2"></div>
                    <div className="star star-position3"></div>
                    <div className="star star-position4"></div>
                    <div className="star star-position5"></div>
                    <div className="star star-position6"></div>
                    <div className="star star-position7"></div>
                </div>
                <div className="box-of-star4">
                    <div className="star star-position1"></div>
                    <div className="star star-position2"></div>
                    <div className="star star-position3"></div>
                    <div className="star star-position4"></div>
                    <div className="star star-position5"></div>
                    <div className="star star-position6"></div>
                    <div className="star star-position7"></div>
                </div>
                <div data-js="astro" className="astronaut">
                    <div className="head"></div>
                    <div className="arm arm-left"></div>
                    <div className="arm arm-right"></div>
                    <div className="body">
                        <div className="panel"></div>
                    </div>
                    <div className="leg leg-left"></div>
                    <div className="leg leg-right"></div>
                    <div className="schoolbag"></div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default LoadingPage;
