import { toast } from "react-hot-toast";
import AbortController from "abort-controller";

const base = `https://fornitori.officinaveneziani.com/api/api.php`;

const controller = new AbortController();
const signal = controller.signal;

const API = {
    /**
     * Ottengo i dati dal server
     * @param {string}  param   Url dove fare la richiesta
     * @param {any}     id      Id per una richiesta eventuale
     * @returns
     */
    get: (param, id = false) => {
        const t = new Promise((resolve, reject) => {
            let url = `${base}/${param}`;
            if (id) url += `/${id}`;
            if (navigator.onLine) {
                let userInfo = JSON.parse(localStorage.getItem("cliente"));
                if (userInfo === null) reject("Utente non loggato");
                //da implementare gli headers nel fetch
                const headers = API.getHeaders();
                fetch(`${url}`, {
                    method: "GET",
                    signal: signal,
                    headers: {
                        Userhash: headers.hash,
                        Userid: headers.id,
                    },
                })
                    .then((res) => res.json())
                    .then(
                        (result) => {
                            //console.log( result );
                            if (result.status) resolve(result);
                            else reject(result.msg);
                        },
                        (error) => {
                            console.error(error);
                            reject(error);
                        }
                    );
            } else reject("Nessuna connessione ad internet");
        });

        if (localStorage.getItem("notifiche") === "true")
            toast.promise(t, {
                loading: "Ottengo i dati",
                success: "Dati ottenuti",
                error: (msg) => `Errore: ${msg}`,
            });

        return t;
    },
    /**
     * Invio dei dati al server
     * @param {string} url      Url dove fare la richiesta
     * @param {object} param    Oggetto da convertire poi con formData
     */
    post: (url, param) => {
        var formData = new FormData();
        formData.append("data", JSON.stringify(param));
        const t = new Promise((resolve, reject) => {
            if (navigator.onLine) {
                fetch(`${base}/${url}`, {
                    method: "POST",
                    body: formData,
                    signal: signal,
                    headers: {
                        Userhash: API.getUser(),
                    },
                })
                    .then((res) => res.json())
                    .then(
                        (result) => {
                            if (result.status == true) resolve(result);
                            else reject(result.msg);
                        },
                        (error) => {
                            console.error(error);
                            reject(error);
                        }
                    );
            } else reject("Nessuna connessione ad internet");
        });

        toast.promise(t, {
            loading: "Invio i dati",
            success: "Dati inviati",
            error: (msg) => `Errore: ${msg}`,
        });

        return t;
    },

    /**
     * Modifica i dati del server
     * @param {string}  url     url dove effettuare la richiesta
     * @param {any}     id      Id della modifica di PUT
     * @param {object}  param   Parametri da passare trasformando in formData
     */
    put: (url, id, param) => {
        var formData = new FormData();
        formData.append("data", JSON.stringify(param));
        const t = new Promise((resolve, reject) => {
            if (navigator.onLine) {
                fetch(`${base}/${url}/${id}`, {
                    method: "PUT",
                    body: formData,
                    signal: signal,
                    headers: {
                        Userhash: API.getUser(),
                    },
                })
                    .then((res) => res.json())
                    .then(
                        (result) => {
                            if (result.status) resolve(result);
                            else reject(result.msg);
                        },
                        (error) => {
                            console.error(error);
                            reject(error);
                        }
                    );
            } else reject("Nessuna connessione ad internet");
        });

        toast.promise(t, {
            loading: "Modifico i dati del server",
            success: "Dati modificati con successo",
            error: (msg) => `Errore: ${msg}`,
        });

        return t;
    },

    /**
     * Cancello i dati dal server
     * @param {string}  url Url dove effettuare la richiesta
     * @param {any}     id  Id Da cancellare
     */
    delete: (url, id) => {
        const t = new Promise((resolve, reject) => {
            if (navigator.onLine) {
                fetch(`${base}/${url}/${id}`, {
                    method: "DELETE",
                    signal: signal,
                    headers: {
                        Userhash: API.getUser(),
                    },
                })
                    .then((res) => res.json())
                    .then(
                        (result) => {
                            if (result.status) resolve(result);
                            else reject(result.msg);
                        },
                        (error) => {
                            console.error(error);
                            reject(error);
                        }
                    );
            } else reject("Nessuna connessione ad internet");
        });

        toast.promise(t, {
            loading: "Cancello i dati dal server",
            success: "Dati cancellati dal server",
            error: (msg) => `Errore: ${msg}`,
        });

        return t;
    },
    /**
     * Esegue la chiamata allo script di login
     * @param {object} dati  I dati da passare allo script
     * @returns Promise con il return della chiamata
     */
    login: (dati) => {
        const t = new Promise((resolve, reject) => {
            if (navigator.onLine) {
                var formData = new FormData();
                formData.append("data", JSON.stringify(dati));
                fetch(`https://fornitori.officinaveneziani.com/api/login.php`, {
                    method: "POST",
                    body: formData,
                    signal: signal,
                })
                    .then((res) => res.json())
                    .then((data) => {
                        if (data.status) resolve(data);
                        else reject(data.msg);
                    })
                    .catch((err) => reject(err));
            } else reject("Devi essere online");
        });

        toast.promise(t, {
            loading: "Effettuo la richiesta",
            success: "Dati ricevuti",
            error: (msg) => `Errore: ${msg}`,
        });

        return t;
    },
    hashLog: (dati) => {
        return new Promise((resolve, reject) => {
            if (navigator.onLine) {
                var formData = new FormData();
                formData.append("data", JSON.stringify(dati));
                fetch(
                    `https://fornitori.officinaveneziani.com/api/hashcheck.php`,
                    {
                        method: "POST",
                        body: formData,
                        signal: signal,
                    }
                )
                    .then((res) => res.json())
                    .then((data) => {
                        if (data.status) resolve(data);
                        else reject(data.msg);
                    })
                    .catch((err) => reject(err));
            } else reject("Devi essere online");
        });
    },
    /**
     * Ottengo i dati dal server del magazzino
     * @param {string}  param   Url dove fare la richiesta
     * @param {any}     id      Id per una richiesta eventuale
     * @returns
     */
    mag: (param, id = false) => {
        const t = new Promise((resolve, reject) => {
            let url = `https://offvenapp.ddns.net/fornitori/api/mag.php/${param}`;
            if (id) url += `/${id}`;
            if (navigator.onLine) {
                fetch(`${url}`, {
                    method: "GET",
                    signal: signal,
                })
                    .then((res) => res.json())
                    .then(
                        (result) => {
                            //console.log( result );
                            if (result.status) resolve(result);
                            else reject(result.msg);
                        },
                        (error) => {
                            console.error(error);
                            reject(error);
                        }
                    );
            } else reject("Nessuna connessione ad internet");
        });

        if (localStorage.getItem("notifiche") === "true")
            toast.promise(t, {
                loading: "Ottengo i dati",
                success: "Dati ottenuti",
                error: (msg) => `Errore: ${msg}`,
            });

        return t;
    },
    /**
     * Ottengo i dati dal server del magazzino
     * @param {string}  codice   Url dove fare la richiesta
     * @returns
     */
    lizy: (codice) => {
        const t = new Promise((resolve, reject) => {
            let url = `https://offvenapp.ddns.net/fornitori/api/lizy.php`;
            if (codice) url += `/${codice}`;
            if (navigator.onLine) {
                fetch(`${url}`, {
                    method: "GET",
                    signal: signal,
                })
                    .then((res) => res.json())
                    .then(
                        (result) => {
                            //console.log( result );
                            if (result.status) resolve(result);
                            else reject(result.msg);
                        },
                        (error) => {
                            console.error(error);
                            reject(error);
                        }
                    );
            } else reject("Nessuna connessione ad internet");
        });

        if (localStorage.getItem("notifiche") === "true")
            toast.promise(t, {
                loading: "Ottengo i dati",
                success: "Dati ottenuti",
                error: (msg) => `Errore: ${msg}`,
            });

        return t;
    },
    price: (codice) => {
        const call = new Promise((resolve, reject) => {
            if (!codice) reject("codice non inserito");
            fetch(
                `https://fornitori.officinaveneziani.com/api/pricerDB.php/${codice}`,
                {
                    method: "GET",
                    signal: signal,
                }
            )
                .then((res) => res.json())
                .then((result) => {
                    if (result.status) resolve(result);
                    else reject("Prodotto non trovato");
                })
                .catch((err) => reject(err));
        });

        if (localStorage.getItem("notifiche") === "true")
            toast.promise(call, {
                loading: "Cerco il prezzo",
                success: "Prezzo trovato",
                error: (msg) => `Errore: ${msg}`,
            });

        return call;
    },
    abort: () => {
        //controller.abort()
    },
    getUser: () => {
        const userHash =
            JSON.parse(localStorage.getItem("cliente")) !== null
                ? JSON.parse(localStorage.getItem("cliente")).dati.hash
                : false;

        return userHash;
    },
    getHeaders: () => {
        const { id, hash } = JSON.parse(localStorage.getItem("cliente")).dati;
        return { id, hash };
    },
    changeUser: (dati, id) => {
        const formData = new FormData();
        formData.append("dati", JSON.stringify(dati));
        const call = new Promise((resolve, reject) => {
            fetch(
                `https://fornitori.officinaveneziani.com/api/account.php/${id}`,
                {
                    method: "POST",
                    body: formData,
                    headers: {
                        Userhash: API.getUser(),
                    },
                }
            )
                .then((res) => res.json())
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => reject(err));
        });

        if (localStorage.getItem("notifiche") === "true")
            toast.promise(call, {
                loading: "Invio i dati",
                success: "Dati inviati correttamente",
                error: (msg) => `Errore: ${msg}`,
            });

        return call;
    },
};

export default API;
