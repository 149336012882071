import React, { useState } from "react";
import IsDark from "../IsDark";
import FloatingInput from "../components/inputs/FloatingInput";
import Lista from "../components/cerca/Lista";
import { motion } from "framer-motion";
import { toast } from "react-hot-toast";

const Cerca = () => {
    const [cercaTemp, setCercaTemp] = useState("");
    const [cerca, setCerca] = useState("");

    const avviaCerca = () => {
        if (cercaTemp.length < 1) {
            toast.error("Inserisci un prodotto da cercare");
            return false;
        }
        setCerca(cercaTemp);
    };

    return (
        <section className={`container`}>
            <div
                className={`${
                    IsDark() ? "bg-dark" : "bg-white"
                } p-2 shadow rounded mb-4 d-flex align-items-center`}
                id="cerca_mag"
            >
                <FloatingInput
                    type="text"
                    name="cerca"
                    id="cerca"
                    label="Cerca un prodotto"
                    margin={false}
                    onChange={setCercaTemp}
                    className="flex-fill rounded-end-0"
                    onEnter={avviaCerca}
                />

                <div
                    className="h-100 d-block align-items-center d-flex ps-3 pe-3 rounded-end bg-primary text-white cursor-pointer"
                    onClick={avviaCerca}
                >
                    <motion.span
                        className="material-symbols-outlined"
                        whileHover={{
                            scale: 1.1,
                        }}
                        whileTap={{
                            scale: 0.9,
                        }}
                    >
                        search
                    </motion.span>
                </div>
            </div>

            <motion.div
                animate={{
                    opacity: cerca.length > 0 ? 1 : 0,
                }}
            >
                <Lista
                    cerca={cerca}
                    newCerca={(val) => {
                        setCercaTemp(val);
                        avviaCerca();
                    }}
                />
            </motion.div>
        </section>
    );
};

export default Cerca;
