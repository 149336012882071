import React, { useEffect, useRef } from "react";
import IsDark from "../IsDark";

/* 
    Table components
        utilizzo con figli per la creazione delle tabelle
    Table contiene le props per l'adattamento della tabella

    table.head e table.body hanno entrambi l'autofix per i vari attr scope
*/

/**
 * Contenitore della tabella
 * @param {bool}    striped         Se deve essere striped o meno
 * @param {bool}    responsive      Se applicare il container responsive
 * @param {bool}    wrap            Se gestire il Wrap
 * @param {string}  className       Eventuali classi aggiuntive
 * @param {string}  cellPadding     Eventuale cellpadding
 */
const Table = (props) => {
    if (props.responsive)
        return (
            <div className="table-responsive">
                <table
                    className={
                        "table" +
                        (props.striped ? " table-striped-columns" : "") +
                        (props.wrap ? " table-wrap" : "") +
                        (props.fixed ? " table-fixed" : "") +
                        (props.className ? ` ${props.className}` : "") +
                        (IsDark() ? " table-dark" : "")
                    }
                    cellPadding={props.cellpadding ? props.cellpadding : ""}
                >
                    {props.children}
                </table>
            </div>
        );
    else
        return (
            <table
                className={
                    "table" + (props.striped && " table-striped-columns")
                }
            >
                {props.children}
            </table>
        );
};

const TableThead = (props) => {
    const tabhead = useRef();

    useEffect(() => {
        tabhead.current.querySelectorAll("th, td").forEach((val) => {
            if (val.getAttribute("scope") == null) {
                val.setAttribute("scope", "col");
            }
        });
    }, [props]);

    return (
        <thead ref={tabhead} className={props.className}>
            {props.children}
        </thead>
    );
};

const TableTbody = (props) => {
    const tabbody = useRef();

    useEffect(() => {
        tabbody.current.querySelectorAll("tr td:nth-child(1)").forEach((td) => {
            if (td.getAttribute("scope") == null)
                td.setAttribute("scope", "row");
        });
    }, [props]);

    return (
        <tbody
            ref={tabbody}
            id={typeof props.id != "undefined" ? props.id : crypto.randomUUID()}
            className={props.className}
        >
            {props.children}
        </tbody>
    );
};

const TableTfoot = (props) => {
    return <tfoot>{props.children}</tfoot>;
};

/* Table.Thead = TableThead;
Table.Tbody = TableTbody; */

export default Object.assign(Table, {
    Thead: TableThead,
    Tbody: TableTbody,
    Tfoot: TableTfoot,
});

Table.defaultProps = {
    striped: true,
    responsive: true,
    wrap: false,
    className: false,
    fixed: false,
    cellpadding: false,
};

/* 
    changelog:
        17/02/2023 [
            -creazione
        ]
*/
